import React, { useEffect } from 'react';
import {  Divider,  Container, Header, Icon, Grid, Button, Segment } from 'semantic-ui-react';
import {longFormName} from '../metadata/utils/GetSettings';
import ReactGA from 'react-ga4';
//const Tracking_Id = "G-EN342X165C";

const handleClick = () =>{
  ReactGA.event({
    category: "LinkedIn Connect button pressed ",
    action: "LinkedIn Connect button pressed ",
    label: "LinkedIn Connect button pressed ", // optional
    value: 1, // optional, must be a number
    nonInteraction: false, // optional, true/false
    transport: "xhr", // optional, beacon/xhr/image
  });
}

function RenderContact(){
    return(
        <Container textAlign='center' text>
            <Segment basic size={'large'}>
                Want to use {longFormName} to formally engage your teams and alumni with official recognitions, rewards, certificates or citations? 
            </Segment>
            <Segment basic size={'large'}>
                Want to commemorate important milestones, inspiring achievements or realizing shared stretch goals?
            </Segment>
            <Segment basic size={'large'}>
                Have custom design or branding needs? More disruptive ideas?
            </Segment>
            <Segment basic size={'large'}>
                <b>Please let us know.</b>
            </Segment>
            
            
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Grid  columns={2}>
                <Grid.Column textAlign='center'>
                    <Header as='h3' icon>
                        <Icon name='mail' />
                        <Header.Content>
                            Write to us
                            <Header.Subheader>
                                bc at coincentives dot io
                            </Header.Subheader>
                        </Header.Content>
                    </Header>
                </Grid.Column>
                <Grid.Column textAlign='center'>
                    <Header as='h3' >
                    
                    <Header.Content>
                        <a href="https://www.linkedin.com/company/skill-accolades/" target="_blank">
                            <Button color='linkedin' onClick={handleClick}>
                                <Icon name='linkedin' /> Skill Accolades
                            </Button>
                        </a>
                        <br></br>
                        <br></br>
                        Connect with us
                    </Header.Content>
                    </Header>
                </Grid.Column>
            </Grid>
      </Container>
    );
}
export default RenderContact;