import React, { useState, useEffect } from 'react';
import { Container, Image, Menu, Message,Divider, Header, Grid, Icon } from 'semantic-ui-react';
import LogoImg from '../images/tt.png';
import {ButtonStyle, ButtonStyleInner, PunchLine, Legals, longFormName} from '../metadata/utils/GetSettings';
import RecognizeImg from '../images/design 2 gold recognize coin.png';
import ThankImg from '../images/design 2 gold acknowledge coin.png';
import CelebrateImg from '../images/design 2 gold award coin.png';

const Welcome = () => {
    return(
        <Container textAlign='center'>
            
            <br/>
            <Image src={LogoImg} size='small' circular centered/>

            <Header size='large'>
                Welcome to {longFormName}! 
            </Header>
            <Image.Group size='tiny'>
                <Image src={RecognizeImg} verticalAlign='middle'/>
                <Image src={CelebrateImg} verticalAlign='middle'/>
                <Image src={ThankImg} verticalAlign='middle'/>
            </Image.Group>
            <Header size='medium' textAlign='center' color='orange'>{PunchLine}</Header>
            
            <Grid padded>
                <Grid.Column>
                    <Message  positive >
                        <Header as='h6'>
                            <Header.Subheader>
                                We recommend that you use a non-custodial wallet like MetaMask.<br/><br/>
                            
                                In case you do not have such a wallet, please select the first option - you can use your email to get a custodial wallet.  
                                <br/><br/>
                                Learn about <a href="https://crypto.com/university/custodial-vs-non-custodial-wallets" target="_blank">custodial and non-custodial wallets</a>
                            </Header.Subheader>
                            
                        </Header>
                    </Message>
                </Grid.Column>
            </Grid>
            <Menu fixed="bottom" text  widths={1} >
                
                <Menu.Item >
                    <Message  floating size='mini'>
                        <Icon name='warning' />
                        By connecting your wallet, you agree to our <a href={Legals['TC']}>Terms of Service</a>,  <a href={Legals['NFT']}>NFT Terms</a> and <a href={Legals['PP']}>Privacy Policy</a>
                    </Message>
                     
                </Menu.Item>
                
                
            </Menu>
            
            
        </Container>
    );
    
}

export default Welcome;