import React, { useEffect } from 'react';
import {  Divider,  Container, Table, Header, Image, Grid, Icon, Button, Segment, Label } from 'semantic-ui-react';
import { ButtonStyle, ButtonStyleInner, polygonScanLink, contractAdd, Legals, shortFormName, longFormName, tokenFamily, shortFormNameSingular, ButtonStyleOuter} from '../metadata/utils/GetSettings';

import HeaderBlock from '../components/HeaderBlock';
import FooterBlock from '../components/FooterBlock';

import RecognizeImg from '../images/tt-recognize.png';
import ThankImg from '../images/tt-thank.png';
import CelebrateImg from '../images/tt-celebrate.png';
import Polygon from '../images/Polygon-POS-Icon.png';
import ReactGA from 'react-ga4';
//const Tracking_Id = "G-EN342X165C";
//import IntelligentWaitingListForm from '../components/IntelligentWaitingListForm';


const recordEvent = (eventText) =>{
  ReactGA.event({
    category: "Button press ".concat(eventText),
    action: eventText,
    label: eventText, // optional
    value: 1, // optional, must be a number
    nonInteraction: false, // optional, true/false
    transport: "xhr", // optional, beacon/xhr/image
  });
}


function Transparency(){
  useEffect(()=>{
    ReactGA.send({ hitType: "pageview", page: "/transparency", title: "Transparency" });
    window.scrollTo(0, 0);
  },[]);
  return (
    <>
      <Container >
        <HeaderBlock header = "Transparency" subheader={"Important Details about ".concat(longFormName)} title={true}  key="transparency"/>
        <Divider hidden />
        <Divider hidden />
        <Container textAlign='center' text>
          <Segment basic >
            <Header size='large' > How do {longFormName} work?</Header>
          </Segment>
          <Grid>
            <Grid.Row>
              <Grid.Column width={16}>
              <span style={{ color: 'grey' }}>{longFormName} use <b>Soul Bound Tokens</b> (<b>non-transferable tokens</b> - both <b>NFTs</b> - Non-Fungible Tokens and <b>SFTs</b> - Semi-Fungible Tokens) as basic building blocks. These can easily be awarded by you to your colleagues as tokens of your appreciation. Once received, {shortFormName} are controlled by the recipients and will serve as a record of their achievements over time, across teams and organisations.</span>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
              <span style={{ color: 'grey' }}>At {longFormName}, we support World Economic Forum's <a href="https://initiatives.weforum.org/reskilling-revolution/home" target="_blank" onClick={()=>recordEvent('Reskilling revolution Clicked')}>Reskilling Revolution</a> initiative. Each {shortFormNameSingular} is mapped to the WEF Global Skills Taxonomy and the Education 4.0 Taxonomy.</span>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row>
              <Grid.Column width={16}>
              <span style={{ color: 'grey' }}>{longFormName} are not vehicles for speculative investment.</span>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        
          <Divider hidden />
          <Divider hidden />
          <Segment basic >
            <Header size='large' > {longFormName} Smart Contract Details</Header>
          </Segment>
          <Table celled padded stackable striped definition>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign='center'></Table.HeaderCell>

                <Table.HeaderCell textAlign='center'>
                  <Header as='h4' >
                    <Image circular src={ThankImg} />
                    {tokenFamily[3]}
                  </Header>
                </Table.HeaderCell>

              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell singleLine>Smart Contract Address</Table.Cell>

                <Table.Cell singleLine>{contractAdd[3]}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell singleLine>Token Standard</Table.Cell>

                <Table.Cell><a href="https://eips.ethereum.org/EIPS/eip-1155" target="_blank">
                  {window.innerWidth>768?
                    <Button basic animated='fade' style={ButtonStyle} onClick={()=>recordEvent('Thank EIP-1155 button clicked')}>
                      <Button.Content visible style={ButtonStyleInner}>ERC1155</Button.Content>
                      <Button.Content hidden style={ButtonStyleInner}><Icon name='ethereum' /></Button.Content>
                    </Button>
                    :
                    <Button as='div'  labelPosition='left' size='medium' fluid  onClick={()=>recordEvent('Thank EIP-1155 button clicked')} >
                      <Label basic style={ButtonStyleOuter} pointing='right'>
                        <Icon name='ethereum' />
                      </Label>
                      <Button basic style={ButtonStyle} fluid> ERC1155</Button>
                    </Button>
                  } 
                </a></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell singleLine>Blockchain</Table.Cell>

                <Table.Cell>Polygon Mainnet</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell singleLine>Transaction Explorer</Table.Cell>

                <Table.Cell>
                  <a href={polygonScanLink.concat("token/",contractAdd[3])} target="_blank">
                    {window.innerWidth>768?
                      <Button basic animated='fade' style={ButtonStyle} onClick={()=>recordEvent('Thank Polygonscan button clicked')}>
                        <Button.Content visible style={ButtonStyleInner}>Polygonscan</Button.Content>
                        <Button.Content hidden style={ButtonStyleInner}><Image src={Polygon} size='mini' centered/></Button.Content>
                      </Button>
                    :
                      <Button as='div'  labelPosition='left' size='medium' fluid  onClick={()=>recordEvent('Thank EIP-1155 button clicked')} >
                        <Label basic style={ButtonStyleOuter} pointing='right'>
                          <Image src={Polygon} size='mini' centered/>
                        </Label>
                        <Button basic style={ButtonStyle} fluid>Polygonscan</Button>
                      </Button>
                    } 
                  </a>
                </Table.Cell>
              </Table.Row>

            </Table.Body>
          </Table>
          <Table celled padded stackable striped definition>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign='center'></Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>
                  <Header as='h4' >
                    <Image circular src={RecognizeImg} />
                    {tokenFamily[1]}
                  </Header>
                </Table.HeaderCell>


              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell singleLine>Smart Contract Address</Table.Cell>
                <Table.Cell singleLine>{contractAdd[1]}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell singleLine>Token Standard</Table.Cell>
                <Table.Cell><a href="https://eips.ethereum.org/EIPS/eip-1155" target="_blank">
                  {window.innerWidth>768?
                    <Button basic animated='fade' style={ButtonStyle} onClick={()=>recordEvent('Thank EIP-1155 button clicked')}>
                      <Button.Content visible style={ButtonStyleInner}>ERC1155</Button.Content>
                      <Button.Content hidden style={ButtonStyleInner}><Icon name='ethereum' /></Button.Content>
                    </Button>
                    :
                    <Button as='div'  labelPosition='left' size='medium' fluid  onClick={()=>recordEvent('Thank EIP-1155 button clicked')} >
                      <Label basic style={ButtonStyleOuter} pointing='right'>
                        <Icon name='ethereum' />
                      </Label>
                      <Button basic style={ButtonStyle} fluid> ERC1155</Button>
                    </Button>
                  }
                </a></Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell singleLine>Blockchain</Table.Cell>
                <Table.Cell>Polygon Mainnet</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell singleLine>Transaction Explorer</Table.Cell>
                <Table.Cell>
                  <a href={polygonScanLink.concat("token/",contractAdd[1])} target="_blank">
                    {window.innerWidth>768?
                      <Button basic animated='fade' style={ButtonStyle} onClick={()=>recordEvent('Thank Polygonscan button clicked')}>
                        <Button.Content visible style={ButtonStyleInner}>Polygonscan</Button.Content>
                        <Button.Content hidden style={ButtonStyleInner}><Image src={Polygon} size='mini' centered/></Button.Content>
                      </Button>
                    :
                      <Button as='div'  labelPosition='left' size='medium' fluid  onClick={()=>recordEvent('Thank EIP-1155 button clicked')} >
                        <Label basic style={ButtonStyleOuter} pointing='right'>
                          <Image src={Polygon} size='mini' centered/>
                        </Label>
                        <Button basic style={ButtonStyle} fluid>Polygonscan</Button>
                      </Button>
                    }
                  </a>
                </Table.Cell>
              </Table.Row>

            </Table.Body>
          </Table>
          <Table celled padded stackable striped definition>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell textAlign='center'></Table.HeaderCell>

                <Table.HeaderCell textAlign='center'>
                  <Header as='h4' >
                    <Image circular src={CelebrateImg} />
                    {tokenFamily[2]}
                  </Header>
                </Table.HeaderCell>


              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell singleLine>Smart Contract Address</Table.Cell>
                <Table.Cell singleLine>{contractAdd[2]}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell singleLine>Token Standard</Table.Cell>
                <Table.Cell>
                  <a href="https://eips.ethereum.org/EIPS/eip-1155" target="_blank">
                    {window.innerWidth>768?
                      <Button basic animated='fade' style={ButtonStyle} onClick={()=>recordEvent('Thank EIP-1155 button clicked')}>
                        <Button.Content visible style={ButtonStyleInner}>ERC1155</Button.Content>
                        <Button.Content hidden style={ButtonStyleInner}><Icon name='ethereum' /></Button.Content>
                      </Button>
                      :
                      <Button as='div'  labelPosition='left' size='medium' fluid  onClick={()=>recordEvent('Thank EIP-1155 button clicked')} >
                        <Label basic style={ButtonStyleOuter} pointing='right'>
                          <Icon name='ethereum' />
                        </Label>
                        <Button basic style={ButtonStyle} fluid> ERC1155</Button>
                      </Button>
                    }
                  </a>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell singleLine>Blockchain</Table.Cell>
                <Table.Cell>Polygon Mainnet</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell singleLine>Transaction Explorer</Table.Cell>
                <Table.Cell>
                  <a href={polygonScanLink.concat("token/",contractAdd[2])} target="_blank">
                    {window.innerWidth>768?
                      <Button basic animated='fade' style={ButtonStyle} onClick={()=>recordEvent('Thank Polygonscan button clicked')}>
                        <Button.Content visible style={ButtonStyleInner}>Polygonscan</Button.Content>
                        <Button.Content hidden style={ButtonStyleInner}><Image src={Polygon} size='mini' centered/></Button.Content>
                      </Button>
                    :
                      <Button as='div'  labelPosition='left' size='medium' fluid  onClick={()=>recordEvent('Thank EIP-1155 button clicked')} >
                        <Label basic style={ButtonStyleOuter} pointing='right'>
                          <Image src={Polygon} size='mini' centered/>
                        </Label>
                        <Button basic style={ButtonStyle} fluid>Polygonscan</Button>
                      </Button>
                    }
                  </a>
                </Table.Cell>
              </Table.Row>

            </Table.Body>
          </Table>
        </Container>
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Grid centered columns={3} stackable>
          <Grid.Column textAlign='center'>
            <Header as='h5' >
              <a href={Legals['PP']} target="_blank">
                {window.innerWidth>768?
                    <Button basic animated='fade'  style={ButtonStyle} onClick={()=>recordEvent('Privacy Policy button clicked')}>
                      <Button.Content visible style={ButtonStyleInner}>Privacy Policy</Button.Content>
                      <Button.Content hidden style={ButtonStyleInner}><Icon name='privacy' /></Button.Content>
                    </Button>
                  :
                    <Button as='div'  labelPosition='left' size='medium' fluid  onClick={()=>recordEvent('Privacy Policy button clicked')} >
                      <Label basic style={ButtonStyleOuter} pointing='right'>
                        <Icon name='privacy' />
                      </Label>
                      <Button basic style={ButtonStyle} fluid>Our Privacy Policy</Button>
                    </Button>
                }
              </a>
            </Header>
          </Grid.Column>
          <Grid.Column textAlign='center'>
            <Header as='h5' >
              <a href={Legals['NFT']} target="_blank">
                {window.innerWidth>768?
                    <Button basic animated='fade'  style={ButtonStyle} onClick={()=>recordEvent('NFT T&Cs button clicked')}>
                      <Button.Content visible style={ButtonStyleInner}>NFT Terms & Conditions</Button.Content>
                      <Button.Content hidden style={ButtonStyleInner}><Icon name='legal' /></Button.Content>
                    </Button>
                  :
                    <Button as='div'  labelPosition='left' size='medium' fluid  onClick={()=>recordEvent('Privacy Policy button clicked')} >
                      <Label basic style={ButtonStyleOuter} pointing='right'>
                        <Icon name='legal' />
                      </Label>
                      <Button basic style={ButtonStyle} fluid>NFT Terms & Conditions</Button>
                    </Button>
                }
              </a>
            </Header>
          </Grid.Column>
          <Grid.Column textAlign='center'>
            <Header as='h5' >
              <a href={Legals['TC']} target="_blank">
                {window.innerWidth>768?
                    <Button basic animated='fade'  style={ButtonStyle} onClick={()=>recordEvent('Terms of Service button clicked')}>
                      <Button.Content visible style={ButtonStyleInner}>Terms of Service</Button.Content>
                      <Button.Content hidden style={ButtonStyleInner}><Icon name='law' /></Button.Content>
                    </Button>
                  :
                    <Button as='div'  labelPosition='left' size='medium' fluid  onClick={()=>recordEvent('Privacy Policy button clicked')} >
                      <Label basic style={ButtonStyleOuter} pointing='right'>
                        <Icon name='law' />
                      </Label>
                      <Button basic style={ButtonStyle} fluid>Terms of Service</Button>
                    </Button>
                }
              </a>
            </Header>
          </Grid.Column>


        </Grid>


        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
               
        
      </Container>
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      {/*<Header textAlign='center' as='h3'>Please join our mailing list</Header>
                <IntelligentWaitingListForm context="mailing list"/>*/} 
      <FooterBlock/>
    </>
  );
}
export default Transparency;
