import React, { useEffect } from 'react';
import {   Divider,  Container, Header } from 'semantic-ui-react';
import {  useAddress } from "@thirdweb-dev/react";
import RenderSelectors from '../components/RenderSelectors';
import HeaderBlock from '../components/HeaderBlock';
import FooterBlock from '../components/FooterBlock';
import { contractAdd, urlPath, shortFormName, longFormName } from '../metadata/utils/GetSettings';
import ReactGA from 'react-ga4';
//const Tracking_Id = "G-EN342X165C";
//import IntelligentWaitingListForm from '../components/IntelligentWaitingListForm';



function Mint(props){
  const address = useAddress();
  useEffect(()=>{
    window.scrollTo(0, 0);
    (props.default===0)?(
      (address !== undefined)?(
        ReactGA.event({
          category: "Wallet Connection",
          action: shortFormName.concat(" > Mint by ", address),
          nonInteraction: false, // optional, true/false
          transport: "xhr", // optional, beacon/xhr/image
        })
      ):
        ReactGA.send({ hitType: "pageview", page: "/mint", title: shortFormName.concat(" > Mint") })
      ):(
        
          (address !== undefined)?(
            ReactGA.event({
              category: "Wallet Connection",
              action: shortFormName.concat(" > Mint > ",urlPath[props.default], " by ", address),
              nonInteraction: false, // optional, true/false
              transport: "xhr", // optional, beacon/xhr/image
            })
        ):(
          ReactGA.send({ hitType: "pageview", page: "/mint/".concat(urlPath[props.default]), title: shortFormName.concat(" > Mint > ", urlPath[props.default]) })
        )
      
    );
  },[]);
  return (
    <>
      <Container >
        <HeaderBlock header = "I would like to" subheader="" title={true}  key="mint"/>
        <Divider hidden />
        <Divider hidden />
        <RenderSelectors key ={(0).toString()} option ={0} default={props.default} />
        <Divider hidden />
        <Divider hidden />
        
      </Container>
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      {/*<Header textAlign='center' as='h3'>Please join our mailing list</Header>
                <IntelligentWaitingListForm context="mailing list"/>*/}
      <FooterBlock/>
      
    </>
    );
}
export default Mint;
