import React, { useContext, useEffect  } from 'react';
import NavigationContext from '../context/navigation';
import {  Divider,  Container, Header, Loader, Icon } from 'semantic-ui-react';
import { ethers } from 'ethers';
import {  useAddress, useContract, useNFTBalance } from "@thirdweb-dev/react";
import HeaderBlock from '../components/HeaderBlock';
import FooterBlock from '../components/FooterBlock';
import FailMessage from '../components/RenderFailMessage';
import ShowOwnNFT from '../components/ShowOwnNFT';
import { contractAdd, urlPath, shortFormName } from '../metadata/utils/GetSettings';
import ReactGA from 'react-ga4';
//import IntelligentWaitingListForm from '../components/IntelligentWaitingListForm';



function Asset(props){
    const { currentPath } = useContext(NavigationContext);
    //const address = useAddress();
    const queriedAddress = currentPath.split('/').at(-2);
    const queriedAssetNumber = currentPath.split('/').at(-1);

    const { contract } = useContract(contractAdd[props.default]);
    const { isLoading, data, error } = useNFTBalance(contract, queriedAddress, queriedAssetNumber);

    useEffect(()=>{
        (!isLoading && !error)?(
            (currentPath.split('/').length===5 && (props.default===2 ) && ethers.utils.isAddress(queriedAddress) && !isNaN(Number(queriedAssetNumber)))?
               /* 
                (address !== undefined)?(
                    ReactGA.event({
                        category: "Wallet Connection",
                        action: "AltCreds > Asset > ".concat(urlPath[props.default], " # ", queriedAssetNumber, " owned by ", queriedAddress, " by ", address),
                        nonInteraction: false, // optional, true/false
                        transport: "xhr", // optional, beacon/xhr/image
                    })
                ):*/
                ReactGA.send({ hitType: "pageview", page: {currentPath}, title: shortFormName.concat(" > Asset > ", urlPath[props.default]," # ", queriedAssetNumber, " owned by ", queriedAddress) })       
            :(
                ReactGA.event({
                    category: "Illegal Address",
                    action: "Illegal Address > Asset > ".concat(currentPath),
                    nonInteraction: true, // optional, true/false
                    transport: "xhr", // optional, beacon/xhr/image
                })
            )
        ):(<></>)
            
    },[]);
    if (currentPath.split('/').length===5 && (props.default===2) && ethers.utils.isAddress(queriedAddress) && !isNaN(Number(queriedAssetNumber))) {
        return(
            <>
                <Container >
                    <HeaderBlock header = {""} subheader={queriedAddress} title={true}  key="asset"/>
                    <Divider hidden />
                    <Divider hidden />
                    {error?(
                                <FailMessage/>
                            ):(isLoading?(
                                    <Header as='h4' icon textAlign='center' disabled>
                                        <Loader size='huge' active inline='centered' />
                                        <Header.Content>Please wait</Header.Content>
                                    </Header>
                                ):(
                                    (data > 0) ?
                                        <ShowOwnNFT key = {"asset view".concat(props.default)} id = {props.default} profile = {3} targetAdd={queriedAddress} targetAsset={queriedAssetNumber} print={0}/>
                                        :   <FailMessage/>
                                ))}
                    
                    
                    
                </Container>
                <Divider hidden />
                <Divider hidden />
                <Divider hidden />
                <Divider hidden />
                {/*<Header textAlign='center' as='h3'>Please join our mailing list</Header>
                <IntelligentWaitingListForm context="mailing list"/>*/}
                <FooterBlock/>
            
            </>
        );
    }
    

  else return (
    <>
        <Container>
            <HeaderBlock header = "" subheader="" title={true}  key="asset-fail"/>
            <FailMessage/>
            
        </Container>
        <FooterBlock/>
    </>
    
    
  );
}

export default Asset;
