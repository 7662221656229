import React, { useEffect } from 'react';
import {Image, Header, Divider, Button, Icon, Container, Segment, Grid, Label } from 'semantic-ui-react';
import Link from '../components/Link';
import { ButtonStyle, ButtonStyleOuter, shortFormName, longFormName, callToAction, tokenFamilyName, discoverMessages} from '../metadata/utils/GetSettings';
import HeaderBlock from '../components/HeaderBlock';
import FooterBlock from '../components/FooterBlock';
import RenderSelectors from '../components/RenderSelectors';
import AccordionComponent from '../components/AccordionComponent';
import ReactGA from 'react-ga4';
import ConceptImg1 from '../images/TakToken Concept 2.png';
import ConceptImg2 from '../images/TakToken Concept 5.png';
import ConceptImg3 from '../images/TakToken Concept 6.png';
import ConceptImg4 from '../images/TakToken Concept 7.png';
import { urlPath } from '../metadata/utils/GetSettings';
import {  useAddress } from "@thirdweb-dev/react";
//const Tracking_Id = "G-EN342X165C";
//import IntelligentWaitingListForm from '../components/IntelligentWaitingListForm';

const recordEvent = (eventText) =>{
  ReactGA.event({
    category: "Button press ".concat(eventText),
    action: eventText,
    label: eventText, // optional
    value: 1, // optional, must be a number
    nonInteraction: false, // optional, true/false
    transport: "xhr", // optional, beacon/xhr/image
  });
}

const ConceptImg = [ConceptImg1,ConceptImg2,ConceptImg3,ConceptImg4];

function Explore(props){
  const address = useAddress();
  useEffect(()=>{
    window.scrollTo(0, 0);
    (props.default===0)?(
      (address !== undefined)?(
        ReactGA.event({
          category: "Wallet Connection",
          action: shortFormName.concat(" > Discover by ",address),
          nonInteraction: false, // optional, true/false
          transport: "xhr", // optional, beacon/xhr/image
        })
      ):
        ReactGA.send({ hitType: "pageview", page: "/discover", title: shortFormName.concat(" > Discover") })
    ):(
      (address !== undefined)?(
        ReactGA.event({
          category: "Wallet Connection",
          action: shortFormName.concat(" > Discover > ", urlPath[props.default]," by ",address),
          nonInteraction: false, // optional, true/false
          transport: "xhr", // optional, beacon/xhr/image
        })
      ):
        ReactGA.send({ hitType: "pageview", page: "/discover/".concat(urlPath[props.default]), title: shortFormName.concat(" > Discover > ", urlPath[props.default]) })
    )
  },[]);
  return (
    <>
      <Container textAlign='center'>
        <HeaderBlock header = {"Discover ".concat(longFormName, props.default>0?" > ":"", props.default>0?tokenFamilyName[props.default]:"", props.default>0?" Family":"")} subheader="" title={true} key="discover"/>
        <Divider hidden />
        <Divider hidden />
         
          <>
            <Container textAlign='center' text>
              {
                /*
                  <Segment basic >
                      <Header size='large' > What are Alternative Credentials?</Header>
                  </Segment>
                */
              }
              
              <Grid relaxed stackable centered columns={2} verticalAlign='middle'>
                <Grid.Row>
                  <Grid.Column> 
                    <Image src={ConceptImg[props.default<2?0:1]} fluid />
                  </Grid.Column>
                
              
                  <Grid.Column textAlign='center'>
                    <Grid.Row>
                      {discoverMessages[props.default].map((message, index)=>(
                        <>
                          <Header size='tiny' color='grey'>{message} </Header>
                        
                          {index!==3?<Divider/>:<></>}  
                        </>
                      ))}
                        
                        
                        
                    </Grid.Row>
                  </Grid.Column>
                </Grid.Row>
                
                  
                
                
              
                

              </Grid>
              <Divider hidden />
              <Divider hidden />
            </Container>
              <Divider/>
                        
              <Header size='medium' color='grey'>There are 3 families of {shortFormName}:</Header>
              
            
            
          </>
        
        
        
        <RenderSelectors key ={(0).toString()} option ={3} default={props.default} />
        <Divider hidden />
        <Divider hidden />
        {/*   
        <Segment placeholder padded='very'>
          <Container text>
            <Segment basic padded='very'>
              <Header size='large' textAlign='center'> How can Alternative Credentials help you?</Header>
            </Segment>
            
            <AccordionComponent/>
          </Container>  
        </Segment>
          
        <Divider hidden />
        <Divider hidden />
        */} 
      </Container>
      <Segment.Group size={window.innerWidth>768?'huge':'small'}>
        <Segment placeholder padded='very'>
          
          
          <Header size={window.innerWidth>768?'medium':'huge'} textAlign='center' color='orange'> 
            
            {callToAction} 
          </Header>
          <Image src={ConceptImg[3]} size = 'large' centered/>
          
          
          </Segment>
      </Segment.Group>
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Grid columns='equal' stackable textAlign='center'>
              <Grid.Column></Grid.Column>
              <Grid.Column>
                <Link to={'/mint'}>
                  {window.innerWidth>768?
                      <Button  animated='fade' size='massive' style={ButtonStyleOuter}  onClick={()=>recordEvent('Discover Top Button Clicked')}>
                        <Button.Content visible>Try {shortFormName}</Button.Content>
                        <Button.Content hidden><Icon name='trophy' /><Icon name='paper plane' /></Button.Content>
                      </Button>
                    :
                      <Button as='div'  labelPosition='left' size='huge' fluid onClick={()=>recordEvent('Discover Bottom Button Clicked')}  >
                        <Label basic style={ButtonStyle} pointing='right'>
                          <Icon name='trophy' /><Icon name='paper plane' />
                        </Label>
                        <Button fluid style={ButtonStyleOuter} size='huge'> Try {shortFormName}</Button>
                      </Button>
                  }
                </Link>
              </Grid.Column>
              <Grid.Column>
                <Link to={'/contact'}>
                  {window.innerWidth>768?
                    <Button animated='fade' size='massive' style={ButtonStyleOuter}  onClick={()=>recordEvent('Try Bottom Button Clicked')}>
                      <Button.Content visible>Get in Touch </Button.Content>
                      <Button.Content hidden><Icon name='write' /><Icon name='bullhorn' /></Button.Content>
                    </Button>
                  :
                    <Button as='div'  labelPosition='left' size='huge' fluid onClick={()=>recordEvent('Contact bottom Button Clicked')}  >
                      <Label basic style={ButtonStyle} pointing='right'>
                        <Icon name='write' /><Icon name='bullhorn' />
                      </Label>
                      <Button  fluid style={ButtonStyleOuter} size='huge'> Get in Touch </Button>
                    </Button>
                 }
                </Link>
            
              </Grid.Column>
              <Grid.Column></Grid.Column>
      
      
        
        
        
        
      </Grid>
      <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        {/*<Header textAlign='center' as='h3'>Please join our mailing list</Header>
                <IntelligentWaitingListForm context="mailing list"/>*/}
      <FooterBlock/>
        
 
    
  </>  
    
  );
}

export default Explore;