import React, { useState, useRef } from 'react';
import QRCode from 'qrcode.react';
import { Container, Image, Button,Loader, Segment, Icon, Divider, Header, Label, List, Grid } from 'semantic-ui-react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {   useContract, useAddress, useNFT } from "@thirdweb-dev/react";

import {taxonomy1, taxonomy2, TTNames, TagStyle, urlPath, HeaderStyle, ThemeColor, ButtonStyle, polygonScanLink, contractAdd, Administrator, myURL, certificateMD, shortFormNameSingular, ButtonStyleOuter} from '../metadata/utils/GetSettings';
import logo from '../images/design 2 gold award coin.png';
import Link from './Link';
import ReactGA from 'react-ga4';


export default function RenderPrint(props) {
  
  
  const address = useAddress();
  
  const metadata = JSON.parse(JSON.stringify((props.file!=="2")?taxonomy1:taxonomy2));
  const CertMetadata = JSON.parse(JSON.stringify(certificateMD));

  const { contract } = useContract(contractAdd["customers"]);
  const { data: allNFTs, isLoading:isReadingNfts, errorReadingNfts } = useNFT(contract, metadata[props.taktok]["Institute"]);
  const nameRecord = TTNames.find((person)=> person.address===props.target);
  const componentRef = useRef(null);

    const recordEvent = () =>{
        ReactGA.event({
        category: "Asset > Print > Download",
        action: "Asset > Print > Download #".concat(props.id, " owned by ", props.target, " by ",address),
        label: "Asset > Print > Download #".concat(props.id, " owned by ", props.target, " by ",address), // optional
        value: 1, // optional, must be a number
        nonInteraction: false, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
        });
    }

  const exportComponentAsPDF = async () => {
        const scale = 3*window.devicePixelRatio || 1;
                
        const canvasOutput = await html2canvas(componentRef.current, { scale:  scale , useCORS: true });

        const canvasWidth = canvasOutput.width;
        const canvasHeight = canvasOutput.height;
        const a3AspectRatio = 420 / 297;

        let imgWidth, imgHeight;

        if (canvasWidth / canvasHeight > a3AspectRatio) {
            // Width-bound
            imgWidth = 420;
            imgHeight = 420 * (canvasHeight / canvasWidth);
        } else {
            // Height-bound
            imgHeight = 297;
            imgWidth = 297 * (canvasWidth / canvasHeight);
        }

        const pdf = new jsPDF({
            orientation: 'landscape',
            unit: 'mm',
            format: [imgWidth, imgHeight]
        });

        const xCentered = (420 - imgWidth) / 2; // Center the image horizontally
        const yCentered = (297 - imgHeight) / 2; // Center the image vertically

        pdf.addImage(canvasOutput.toDataURL('image/png'), 'PNG', 0, 0, imgWidth, imgHeight);
            
        pdf.save(''.concat(CertMetadata[metadata[props.taktok]["Certificate Type"]]["topics"].includes("Level")?'Level - ':'',(metadata[props.taktok]["level"]!==undefined && metadata[props.taktok]["level"]!=="")?metadata[props.taktok]["level"]:'',(metadata[props.taktok]["level"]!==undefined && metadata[props.taktok]["level"]!=="")?" - ":"", metadata[props.taktok]["Certificate"]," - ",nameRecord["prefix"]," . ", nameRecord["firstName"], " ",nameRecord["secondName"],'.pdf'));
        recordEvent();
    };
    
    

  return(
    address ===undefined?
    <>
        <Header as='h4' icon textAlign='center' disabled>
            <Loader size='huge' active inline='centered' />
            <Header.Content>Please connect your wallet</Header.Content>
        </Header>
    </>
    :
    ((props.target === address || address === Administrator) && nameRecord!==undefined)?
        (isReadingNfts)? (
            
            <Header as='h4' icon textAlign='center' disabled key={'wait'.concat(props.id)}> 
                <Loader size='huge' active inline='centered' />
            </Header>
           
        ) : (
                (errorReadingNfts)?(
                
                    <Header as='h4' icon textAlign='center' disabled key={'error'.concat(props.id)}> 
                        <Icon name='exclamation circle' color='red'/><Header.Content>There seems to be a problem... please try again</Header.Content>
                    </Header>
                
                ):(
                    
                    <Container textAlign='center'>
                        <div ref={componentRef} style={{ width: '100%', height: '100%' }}>
                            <Segment>
                                <Segment  textAlign='center' padded='very'>
                                    <Header size='huge' textAlign='center' color='orange'>
                                        {allNFTs.metadata.name} 
                                    </Header>
                                    <Container>
                                        {allNFTs.metadata.description!==null?
                                            <>
                                                <Header size='large' textAlign='center' color='orange'>{allNFTs.metadata.description}</Header>
                                            </>
                                            :<></>
                                        }
                                        
                                        
                                        <Header size='large' textAlign='center'>{CertMetadata[metadata[props.taktok]["Certificate Type"]]["Certificate Type"]}</Header>
                                        
                                    </Container>
                                    <Divider hidden />
                                    <Grid container verticalAlign='middle' columns={3}>
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Image src={allNFTs.metadata.image} size='small' centered verticalAlign='middle'/>
                                            </Grid.Column>
                                            <Grid.Column>
                                                {CertMetadata[metadata[props.taktok]["Certificate Type"]]["line1"]}
                                                
                                                <Header size='small'><br/>
                                                    {nameRecord["prefix"]}. {nameRecord["firstName"]} {nameRecord["secondName"]} 
                                                    <br/>
                                                    {CertMetadata[metadata[props.taktok]["Certificate Type"]]["topics"].includes("Level") &&
                                                    metadata[props.taktok]["Occassion"]==="course completion"?
                                                    <><br/>Level {metadata[props.taktok]["level"]} {metadata[props.taktok]["Certificate"]}</>:
                                                    <></>}
                                                </Header>
                                                
                                                {CertMetadata[metadata[props.taktok]["Certificate Type"]]["line2"]} 
                                                <Header size='small'>
                                                    {metadata[props.taktok]["Occassion"]==="event"?
                                                    <>{metadata[props.taktok]["Certificate"]}</>:
                                                    <></>}
                                                </Header>
                                                {CertMetadata[metadata[props.taktok]["Certificate Type"]]["topics"].includes("Date")?
                                                    <><b>on {metadata[props.taktok]["Date"]}  </b><br/></>:<></>} 
                                                    <br/>
                                            </Grid.Column>
                                            <Grid.Column>
                                                <Image src= {logo} size='small' centered verticalAlign='middle' />
                                                <p style={{ fontSize: '9px', color:ThemeColor }}>Powered by <u><a href={myURL} target="_blank">{myURL}</a></u></p>
                                            </Grid.Column>
                                        </Grid.Row>
                                        
                                        <Grid.Row>
                                            <Grid.Column>
                                                <Link to={"/asset/".concat(urlPath[2],"/",props.target,"/",props.id)}>
                                                    <QRCode 
                                                        value={myURL.concat("/asset/",urlPath[2],"/",props.target,"/",props.id)} 
                                                        size={60} 
                                                        fgColor={ThemeColor}
                                                    />
                                                    <br/>Verify this {CertMetadata[metadata[props.taktok]["Certificate Type"]]["main"]}
                                                </Link>
                                            </Grid.Column>
                                            
                                            <Grid.Column>
                                                
                                                <Header size='medium' textAlign='center'>{metadata[props.taktok]["Teacher"]}</Header>
                                                {metadata[props.taktok]["Teacher Details"]!==undefined?
                                                    <>
                                                        {metadata[props.taktok]["Teacher Details"][0]!==undefined?metadata[props.taktok]["Teacher Details"][0]:<></>}
                                                        {metadata[props.taktok]["Teacher Details"][1]!==undefined?<><br/>{metadata[props.taktok]["Teacher Details"][1]}</>:<></>}
                                                    </>
                                                    :<></>
                                                }
                                            </Grid.Column>

                                            <Grid.Column>
                                                <a href={polygonScanLink.concat("tx/",props.hash)} target="_blank">
                                                    <QRCode 
                                                        value={polygonScanLink.concat("tx/",props.hash)} 
                                                        size={60} 
                                                        fgColor={ThemeColor}
                                                    />
                                                    <br/>View this transaction on the blockchain
                                                </a>
                                            </Grid.Column>
                                        </Grid.Row>
                                        <Grid.Row></Grid.Row>
                                        
                                        <Grid.Row centered>
                                            
                                                {
                                                    CertMetadata[metadata[props.taktok]["Certificate Type"]]["topics"].includes("Signatories")?
                                                        <>
                                                            <Header size='medium' textAlign='center'>Our sincerest gratitude to our judges...</Header>
                                                            <Divider clearing/>
                                                            <Grid container verticalAlign='middle' columns={metadata[props.taktok]["Signatories"].length}>
                                                                {metadata[props.taktok]["Signatories"].map((sign)=>(
                                                                    <Grid.Column>
                                                                        <Header size='medium' textAlign='center'>{sign[0]!==undefined?sign[0]:<></>}</Header>
                                                                        {sign[1]!==undefined?sign[1]:<></>}
                                                                    </Grid.Column>
                                                                ))}

                                                            </Grid>
                                                        </>
                                                    :<></>
                                                }
                                            
                                        </Grid.Row>
                                    </Grid>
                                    

                                </Segment>
                            </Segment>
                        </div>
                        <Divider hidden />
                        <Divider hidden />
                        <Grid columns='four' stackable>
              
                            <Grid.Column>
                            </Grid.Column>
                            <Grid.Column>
                                <Link to={"/asset/".concat(urlPath[2],"/",props.target,"/",props.id)}>
                                    {window.innerWidth>768?
                                        <Button animated='fade' size='medium' style={ButtonStyle} fluid >
                                            <Button.Content visible>Detailed view </Button.Content>
                                            <Button.Content hidden><Icon name='zoom-in' /></Button.Content>
                                        </Button>
                                    :
                                        <Button as='div'  labelPosition='left' size='medium' fluid   >
                                            <Label basic style={ButtonStyleOuter} pointing='right'>
                                                <Icon name='zoom-in' />
                                            </Label>
                                            <Button basic style={ButtonStyle} fluid>Detailed view</Button>
                                        </Button>
                                    }
                                </Link>
                            </Grid.Column>
                            <Grid.Column>
                                {window.innerWidth>768?
                                    <Button animated='fade' size='medium' style={ButtonStyle} onClick={exportComponentAsPDF}>
                                        <Button.Content visible style={{color: ThemeColor}}>{'Download your '.concat(shortFormNameSingular,' as PDF')}</Button.Content>
                                        <Button.Content hidden style={{color: ThemeColor}}><Icon name='download' /><Icon name='file pdf' /></Button.Content>
                                    </Button>
                                :
                                    <Button as='div'  labelPosition='left' size='medium' fluid   >
                                        <Label basic style={ButtonStyleOuter} pointing='right'>
                                            <Icon name='download' />
                                        </Label>
                                        <Button basic style={ButtonStyle} fluid>{'Download your '.concat(shortFormNameSingular,' as PDF')}</Button>
                                    </Button>
                                }   
                            </Grid.Column>
                            <Grid.Column>
                            </Grid.Column>
                            
                            
                        </Grid>

                        
                    </Container>
                )
        )
    : 
        props.target !== address ?
            <Header as='h4' icon textAlign='center' disabled>
                <Icon name='exclamation' color='red'/><Header.Content>Incorrect address... please check the details of the asset you want to see </Header.Content>
            </Header>
        :
            <Link to='/contact'>
                <Header as='h4' icon textAlign='center'>
                    <Icon name='write' size={'large'} style = {HeaderStyle}/>
                    <Header.Subheader >
                        Please contact us
                    </Header.Subheader>
                </Header>
                
            </Link>
        
        
  );
}