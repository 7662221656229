import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyCkp2cljjn0NL_wrh9v14kWoebuHRUZWlo",
    authDomain: "alternative-credentials.firebaseapp.com",
    projectId: "alternative-credentials",
    storageBucket: "alternative-credentials.appspot.com",
    messagingSenderId: "545152907974",
    appId: "1:545152907974:web:d28b2db661302b3d0fb5c7",
    measurementId: "G-H7FFDLVCCD"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);
