import React, { useEffect, useState } from 'react';
import { ethers } from 'ethers';
import Web3 from 'web3';
import {  useContract, useAddress, useOwnedNFTs, useMetadata, useContractEvents } from "@thirdweb-dev/react";
import { Card, Icon, Header, Segment,  Loader, Image, Container, Divider, Grid } from 'semantic-ui-react';
import RenderSingleCard from './RenderSingleCard';
import RenderCards from './renderOwnCards';
import RenderShare from './ProfileLinkShare';
import {taxonomy1, taxonomy2,  urlText, urlPath, contractAdd, ERC1155_ABI, Administrator, /*PaperWallets,*/ shortFormName, shortFormNameSingular} from '../metadata/utils/GetSettings';
import RenderPrint from './RenderPrint';
//import { set } from 'react-ga';






function ShowOwnNFTGallery(props){
  const [errors, recordErrors] = useState();
  const [events, setEvents] = useState([
    {

    }
  ]);
  
  const [cardLoaded, setCardLoaded] = useState(false);

  
  
    useEffect(() => {
      if (props.id!==0) {
        const fetchEvents = async () => {
          if (window.ethereum!==undefined) {
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const contract = new ethers.Contract(contractAdd[props.id], new ethers.utils.Interface(ERC1155_ABI[props.id]), provider);
            
            const filter = contract.filters.TransferSingle(); // or any other event
            const logs = await provider.getLogs({
              fromBlock: 50968905,
              toBlock: 'latest',
              address: contractAdd[props.id],
              topics: filter.topics,
            });
            
            /*
            const timeStamp =  async (blockNumber) => {
              let ts = await provider.getBlock(blockNumber);
              //console.log(ts.timestamp);
              return ts.timestamp;
            }
            */
            
            const parsedLogs = logs.map( (log) =>  {
              const parsedLog = contract.interface.parseLog(log);
              //console.log(parsedLog);
              //const block = await provider.getBlock(log.blockNumber);
              return {
                amount: parseInt(parsedLog.args['value']['_hex'],16),
                block: log.blockNumber,
                operator: parsedLog.args[0],
                from: parsedLog.args[1],
                to: parsedLog.args[2],
                tokenNo: parseInt(parsedLog.args['id']['_hex'],16),
                hash:log.transactionHash,
                //timestamp: new Date(block * 1000) ,
                ...parsedLog.args
                };
            });
            setEvents(parsedLogs);
            
            
          } else {
            const provider = new Web3.providers.HttpProvider("https://polygon-mainnet.infura.io/v3/1b3a6035167f4ac9a382fa56b7c15574");
            const web3 = new Web3(provider);
            const contract = new web3.eth.Contract(ERC1155_ABI[props.id], contractAdd[props.id]);

            const pastEvents = await contract.getPastEvents('TransferSingle', {
              fromBlock: 50968905,
              toBlock: 'latest'
            });
            
            const timeStamp = async (blockNumber) => {
              let ts = await web3.eth.getBlock(blockNumber);
              //console.log(new Date(Number(ts.timestamp)* 1000));
              return Promise.resolve(new Date(ts.timestamp* 1000));
            }
            //console.log(Number(pastEvents[0].returnValues.value));

            const parsedEvents = pastEvents.map( (event) =>  {
              //const parsedLog = contract.interface.parseLog(log);
              //const block = await provider.getBlock(log.blockNumber);
              //console.log(event);
              return {
                amount: Number(event.returnValues.value),
                block: Number(event.blockNumber),
                operator: event.returnValues.operator,
                from: event.returnValues.from,
                to: event.returnValues.to,
                tokenNo: Number(event.returnValues.id),
                hash:event.transactionHash,
                //timestamp: timeStamp(Number(event.blockNumber)) ,
                //...parsedLog.args,
                };
            });
            setEvents(parsedEvents);
            //console.log(parsedEvents);
          }
          
      };
    
      fetchEvents();
      }
      
  }, [contractAdd[props.id]]);
 
  

  const { contract } = useContract(contractAdd[props.id]);
  const userAdd = useAddress();
  const address = props.profile!==1?props.targetAdd:userAdd;
  const { data: allNFTs, isLoading:isReadingNfts, errorReadingNfts } = useOwnedNFTs(contract, address);
  const { data: metadata, isLoading: loadingMetadata, errorMetadata } = useMetadata(contract);
                                                                                                
  const [updated,update]= useState(false);
  
  const taxMetadata = {1: JSON.parse(JSON.stringify(taxonomy1)),2: JSON.parse(JSON.stringify(taxonomy2))};

  const [selected, setFilter] = useState(
    {
      "ST" : {"L1": new Set(),"L2": new Set(),"L3": new Set(),"L4": new Set()},
      "ET" : {"L1": new Set(),"L2": new Set(),"L3": new Set()},
      "competancy" : new Set()
    }
  );

  const updateTag = (tax,level,newValue,operation) => {
    const set2 = selected;
    tax==="competancy"?(operation===1?set2[tax].add(newValue):set2[tax].delete(newValue)):(
      operation===1?set2[tax][level].add(newValue):set2[tax][level].delete(newValue)
    )
    setFilter(set2);
    update(!updated);
    setFilter(set2); 
  }

  const determineFilter = (file, token, type, level) => {
    let lST = false;
    let lvl = {1:"L1", 2:"L2", 3:"L3", 4:"L4"};
    taxMetadata[file][token]["tax"].map((txDetail)=>( 
      lST=lST||selected[type][lvl[level]].has(txDetail[type][lvl[level]])
    ))
    return lST;
  }

  const determineLevel = (file, token) => {
    return selected["competancy"].has(taxMetadata[file][token]["level"])
  }
  
  const applyFilter = (tax,level,selectedTag) => {
    //console.log(selected);
    //console.log(level, selectedTag);
    tax==="competancy"?(selected[tax].has(selectedTag)?updateTag(tax,level,selectedTag,0):updateTag(tax,level,selectedTag,1)):(
      selected[tax][level].has(selectedTag)?updateTag(tax,level,selectedTag,0):updateTag(tax,level,selectedTag,1)
    );
    
  }

  const filterByToken =  (events1,tId,add) => {
    //const provider = new ethers.providers.Web3Provider(window.ethereum);
    let filtered = [{self:true, in:[], out:[], net:1, hash:[]}, {self:false, in:[], net:1, hash:[]}];
    let sum = 0;
    
    events1.map((event)=>{
      if (event['tokenNo']===tId){
        if (event['operator']=== add) {
          if (event['to']===add){
            filtered[0].in.push(
              {
                from: event['from'],//must be 0x0
                amount: parseInt(event['amount'])

              }
            )
            filtered[0].hash.push(event.hash);
          } 
          
          if (event['from']===add){
            filtered[0].out.push(
              {
                to: event['to'],
                amount: parseInt(event['amount'])
              }
            )
          }
          
        } else {
          if (event['to']===add){
            filtered[1].in.push(
              {
                from: event['from'],
                amount: parseInt(event['amount']),
              }
            );
            filtered[1].hash.push(event.hash);
          }
        }
         
      }  
        
      
    })
  
    
    let sum1 = filtered[0].in.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0);
    let sum2 = filtered[0].out.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0);
    let sum3 = filtered[1].in.reduce((accumulator, currentValue) => accumulator + currentValue.amount, 0);
    
    filtered[0].net = sum1-sum2;
    
    filtered[1].net = sum3;
    //console.log(filtered);
    return filtered;  
  }
  
  const validateCertificate =  (events1,tId,add) => {
    let isValid = true;

    let subList = [];

    events1.map((event)=>{
      if (event['tokenNo']===tId){
        if (event['to']===add) {
          subList.push(event);
        }
      }
    })

    //console.log(subList);
    
    subList.map((event)=>{
      if (event['tokenNo']===tId){
        if (event['operator']=== Administrator /*|| PaperWallets.indexOf(event['operator'])*/!== -1) {
          if (event['to']===add){
            if (event['from']==='0x0000000000000000000000000000000000000000' || event['from']=== Administrator){
              isValid = isValid && true;
            }
            else{
              isValid = false;
            }
          }  
        }
        else{
          isValid = false;
        }
         
      }  
        
      
    })

    return isValid;

  }
  
  return (
    < >
      
      {(props.id===0)?(
        <Header as='h4' textAlign='center'>

          <Header.Content>
              Please select an {shortFormNameSingular} family

          </Header.Content>
        </Header>
      ):(
        < >
          {(address===undefined && (props.profile===1))?(
            <Header as='h4' icon textAlign='center' disabled>
              <Loader size='huge' active inline='centered' />
              <Header.Content>Please connect your wallet</Header.Content>
            </Header>
          ):(
            (isReadingNfts || loadingMetadata )? (
              < >
                <Header as='h4' icon textAlign='center' disabled>
                  <Loader size='huge' active inline='centered' />
                  <Header.Content>Please wait</Header.Content>
                </Header>

              </ >
            ) : (
                  (errorReadingNfts || errorMetadata )?(
                    < >
                      (errorReadingNfts)?(console.log('error reading NFTs')):(<></>)
                      (errorMetadata)?(console.log('error metadata')[]):(<></>)
                      
                    </ >
                  ):(
                      <>
                        {props.print===1?<></>:
                          <Header as='h2' textAlign='center'>
                            <Image src = {metadata.image} size = 'massive' circular spaced />
                             {props.profile===3? userAdd!==undefined ? ((props.target===userAdd || userAdd === Administrator)?metadata.name:"Verify ".concat(metadata.name, " ",shortFormNameSingular," # ",props.targetAsset)):"Verify ".concat(metadata.name," ",shortFormNameSingular," # ",props.targetAsset):metadata.name}
                            <Header.Subheader>
                              {props.profile===2?
                                  "Here are all the ".concat(shortFormName," of the '", metadata.name,"' family owned by ",props.targetAdd," ..."):
                                  props.profile===1?"Here are all your ".concat(shortFormName," of the '",metadata.name,"' family ..."):
                                  userAdd!==undefined ?((props.target===userAdd || userAdd === Administrator)?shortFormNameSingular.concat(" # ",props.targetAsset, " owned by ", address):"Owned by ".concat(address)):"Owned by ".concat(address)
                              }
                            </Header.Subheader>
                          </Header>
                        } 
                        <Divider hidden />
                        {(allNFTs.length === 0)? (
                          
                            <Header as='h2' icon textAlign='center'>
                              <Icon name='thumbs down' />
                                So sorry
                              <Header.Subheader>
                                {props.profile===2?"No ".concat(shortFormName," recieved so far..."):(props.profile===1?"You are yet to mint/ receive ".concat(shortFormName,"..."):<></>)}
                              </Header.Subheader>
                            </Header>
                          
                        ):(
                            props.profile!==3?
                            <>
                              <Divider hidden />
                              <Divider hidden />
                              
                              {props.profile===1 ?(
                                <>
                                  <Container textAlign='center'>
                                    <RenderShare link={urlText} path={"/profile/".concat(urlPath[props.id],"/",address)} collection={metadata.name} logo = {metadata.image}/>
                                  </Container>
                                  <Divider hidden />
                                </>
                              ):
                              <></>}
                              
                              <Card.Group centered stackable itemsPerRow={3} key={"card".concat(props.profile, props.id)}>
                                {allNFTs.map((nft) => (
                                   (
                                    (
                                      (selected.ST.L1.size === 0) &&
                                      (selected.ST.L2.size === 0) &&
                                      (selected.ST.L3.size === 0) &&
                                      (selected.ST.L4.size === 0) &&
                                      (selected.ET.L1.size === 0) &&
                                      (selected.ET.L2.size === 0) &&
                                      (selected.ET.L3.size === 0) &&
                                      (selected.competancy.size === 0)
                                    ) ||
                                    (
                                      (selected.ST.L1.size > 0 && determineFilter(nft.metadata.attributes[4]["value"].toString(), nft.metadata.attributes[5]["value"].toString(), "ST", 1)) ||
                                      (selected.ST.L2.size > 0 && determineFilter(nft.metadata.attributes[4]["value"].toString(), nft.metadata.attributes[5]["value"].toString(), "ST", 2)) ||
                                      (selected.ST.L3.size > 0 && determineFilter(nft.metadata.attributes[4]["value"].toString(), nft.metadata.attributes[5]["value"].toString(), "ST", 3)) ||
                                      (selected.ST.L4.size > 0 && determineFilter(nft.metadata.attributes[4]["value"].toString(), nft.metadata.attributes[5]["value"].toString(), "ST", 4)) ||
                                      (selected.ET.L1.size > 0 && determineFilter(nft.metadata.attributes[4]["value"].toString(), nft.metadata.attributes[5]["value"].toString(), "ET", 1)) ||
                                      (selected.ET.L2.size > 0 && determineFilter(nft.metadata.attributes[4]["value"].toString(), nft.metadata.attributes[5]["value"].toString(), "ET", 2)) ||
                                      (selected.ET.L3.size > 0 && determineFilter(nft.metadata.attributes[4]["value"].toString(), nft.metadata.attributes[5]["value"].toString(), "ET", 3)) ||
                                      (selected.competancy.size > 0 && determineLevel(nft.metadata.attributes[4]["value"].toString(), nft.metadata.attributes[5]["value"].toString()))
                                    )
                                  )?
                                  filterByToken(events,Number(nft.metadata.id),address).map((direction)=>(
                                    (direction.net>0 && ((!direction.self && props.profile===2)|| ( props.profile===1)) )?
                                      <>
                                        
                                        <RenderCards
                                          key = {nft.metadata.id.toString().concat(nft.metadata.name,nft.metadata.description,nft.metadata.image,direction.self)}
                                          id = {nft.metadata.id}
                                          balance = {nft.quantityOwned}
                                          nftImage = {nft.metadata}
                                          name = {nft.metadata.name}
                                          description = {nft.metadata.description}
                                          contractImage = {metadata.image}
                                          contractName = {metadata.name}
                                          contract = {contractAdd[props.id]}
                                          file = {nft.metadata.attributes[4]["value"]}
                                          taktok = {nft.metadata.attributes[5]["value"]}
                                          filter = {applyFilter}
                                          selectedFilters = {selected}
                                          transferable = {direction}
                                          profile = {props.profile}
                                          target = {address}
                                          valid = {validateCertificate(events,Number(nft.metadata.id),address)}
                                        />
                                        
                                      </>
                                    : <></>
                                  ))
                                  :
                                  (
                                    <></>
                                  )

                                ))}
                                
                              </Card.Group>
                              {/*!cardLoaded?(
                                <Header as='h2' icon textAlign='center'>
                                  <Icon name='thumbs down' />
                                    So sorry
                                  <Header.Subheader>
                                    {props.profile===2?"No ".concat(shortFormName," recieved so far..."):"You are yet to mint/ receive ".concat(shortFormName,"...")}
                                  </Header.Subheader>
                                </Header>
                              ):<></>*/}  

                            </>:
                            <>
                              
                              {props.print===0?
                                <Card.Group centered stackable itemsPerRow={2} key={"card".concat(props.profile, props.id)}>
                                  {allNFTs.map((nft) => (
                                    nft.metadata.id===props.targetAsset ? 
                                        <>
                                          
                                          <RenderSingleCard
                                              key = {nft.metadata.id.toString().concat("print",nft.metadata.name,nft.metadata.description,nft.metadata.image)}
                                              id = {nft.metadata.id}
                                              balance = {nft.quantityOwned}
                                              nftImage = {nft.metadata}
                                              name = {nft.metadata.name}
                                              description = {nft.metadata.description}
                                              contractImage = {metadata.image}
                                              contractName = {metadata.name}
                                              contract = {contractAdd[props.id]}
                                              target = {address}
                                              file = {nft.metadata.attributes[4]["value"]}
                                              taktok = {nft.metadata.attributes[5]["value"]}
                                              valid = {validateCertificate(events,Number(nft.metadata.id),address)}
                                              print = {props.print}
                                              hash = {filterByToken(events,Number(nft.metadata.id),address)[address===Administrator?0:1].hash.at(-1)}
                                          />
                                        </>
                                    : <></>))}
                                </Card.Group>
                                : allNFTs.map((nft) => (
                                    nft.metadata.id===props.targetAsset ?
                                      <RenderPrint  
                                        key = {nft.metadata.id.toString().concat(nft.metadata.name,nft.metadata.description,nft.metadata.image)}
                                        id = {nft.metadata.id}
                                        balance = {nft.quantityOwned}
                                        nftImage = {nft.metadata}
                                        name = {nft.metadata.name}
                                        description = {nft.metadata.description}
                                        contractImage = {metadata.image}
                                        contractName = {metadata.name}
                                        contract = {contractAdd[props.id]}
                                        target = {address}
                                        file = {nft.metadata.attributes[4]["value"]}
                                        taktok = {nft.metadata.attributes[5]["value"]}
                                        valid = {validateCertificate(events,Number(nft.metadata.id),address)}
                                        hash = {filterByToken(events,Number(nft.metadata.id),address)[address===Administrator?0:1].hash.at(-1)}
                                      />
                                    :<></>
                                  ))
                                }
                            </>
                        )}
                      </>
                    )
                )
              )}
            </ >
        )}

    </ >);

}

export default ShowOwnNFTGallery;
