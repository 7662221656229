import React, { useEffect } from 'react';
import Cookiebot from 'react-cookiebot';
import RouteU from './components/RouteUncertain';
import Route from './components/Route';
import Home from './pages/Home';
import Mint from './pages/Mint';
import MyTaktoks from './pages/MyTaktoks';
import Transparency from './pages/Transparency';
import Contact from './pages/Contact';
import Profile from './pages/Profile';
import Asset from './pages/Asset';
import Print from './pages/Print';
import Explore from './pages/Explore';
import Creator from './pages/Creator';
import ReactGA from 'react-ga4';
import {contractAdd, urlPath,shortFormName} from './metadata/utils/GetSettings'; 
const Tracking_Id = "G-9JB1NNEZ2T";



function App(){
    useEffect(()=>{
    ReactGA.initialize(Tracking_Id);
    window.scrollTo(0, 0);
  },[]);

    return (


      < >
       
        <Route path="/">
          <Home/>
        </Route>
        <Route path="/mint">
          <Mint default={0}/>
        </Route>
        <Route path="/mint-custom">
          <Contact/>  
        </Route>
        <Route path={"/mint/".concat(urlPath[1])}>
          <Mint default={1}/>
        </Route>
        <Route path={"/mint/".concat(urlPath[2])}>
          <Mint default={2}/>
        </Route>
        
        <Route path={"/mint/".concat(urlPath[3])}>
          <Mint default={3}/>
        </Route>
        <Route path={"/my".concat(shortFormName)}>
          <MyTaktoks default={0}/>
        </Route>
        <Route path={"/my".concat(shortFormName,"/",urlPath[1])}>
          <MyTaktoks default={1}/>
        </Route>
        <Route path={"/my".concat(shortFormName,"/",urlPath[2])}>
          <MyTaktoks default={2}/>
        </Route>
        
        <Route path={"/my".concat(shortFormName,"/",urlPath[3])}>
          <MyTaktoks default={3}/>
        </Route>
        <Route path="/transparency">
          <Transparency/>
        </Route>
        <Route path="/contact">
          <Contact/>
        </Route>
        <RouteU path="/profile" option={1}> {/* Option 1 means when path string is sperated by '/', the last item is user address*/}
          <Profile default={0}/>
        </RouteU>
        <RouteU path={"/profile/".concat(urlPath[1])} option={1}>
          <Profile default={1}/>
        </RouteU>
        <RouteU path={"/profile/".concat(urlPath[2])} option={1}>
          <Profile default={2}/>
        </RouteU>
        
        <RouteU path={"/profile/".concat(urlPath[3])} option={1}>
          <Profile default={3}/>
        </RouteU>
        {/* Option 1 means when path string is sperated by '/', the last but one item is user address and the last item is a number*/}
        {/*
        <RouteU path={"/asset/".concat(contractAdd[1])} option={2}> 
          <Asset default={1}/>
        </RouteU>
        */}
        <RouteU path={"/asset/".concat(urlPath[2])} option={2}>
          <Asset default={2}/>
        </RouteU>
        {/*
        <RouteU path={"/asset/".concat(contractAdd[3])} option={2}>
          <Asset default={3}/>
        </RouteU>
        */}
        <RouteU path={"/print/".concat(urlPath[2])} option={2}>
          <Print default={2}/>
        </RouteU>
        <Route path="/discover">
          <Explore default={0}/>
        </Route>
        <Route path={"/discover/".concat(urlPath[1])}>
          <Explore default={1}/>
        </Route>
        <RouteU path={"/creator"} option={1} >
          <Creator/>
        </RouteU>
        {/*
          <Route path={"/mint/".concat(urlPath[4])}>
            <Mint default={2}/>
          </Route>
          <Route path={"/myTakToks/".concat(urlPath[4])}>
            <MyTaktoks default={2}/>
          </Route>
          <RouteU path={"/profile/".concat(urlPath[4])} option={1}>
            <Profile default={2}/>
          </RouteU>
          <Route path={"/discover/".concat(urlPath[4])}>
            <Explore default={2}/>
          </Route>
        */
        }
        <Route path={"/discover/".concat(urlPath[2])}>
          <Explore default={2}/>
        </Route>
        <Route path={"/discover/".concat(urlPath[3])}>
          <Explore default={3}/>
        </Route>

        
        <Cookiebot 
          domainGroupId={"99eb3ca1-628f-4177-b77b-4f5c36ef9cc2"}
          showDialog={true} 
        />
      </ >

    );
}
export default App;
