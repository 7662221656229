import React, { useContext, useEffect  } from 'react';
import NavigationContext from '../context/navigation';
import {  Divider,  Container, Header, Loader, Icon } from 'semantic-ui-react';
import { ethers } from 'ethers';
import {  useAddress, useContract, useNFTBalance } from "@thirdweb-dev/react";
import HeaderBlock from '../components/HeaderBlock';
import FooterBlock from '../components/FooterBlock';
import FailMessage from '../components/RenderFailMessage';
import ShowOwnNFT from '../components/ShowOwnNFT';
import { contractAdd, urlPath, Administrator, shortFormName, longFormName } from '../metadata/utils/GetSettings';
import ReactGA from 'react-ga4';



function Print(props){
    const address = useAddress();
    const { currentPath } = useContext(NavigationContext);
    const queriedAddress = currentPath.split('/').at(-2);
    const queriedAssetNumber = currentPath.split('/').at(-1);

    const { contract } = useContract(contractAdd[props.default]);
    const { isLoading, data, error } = useNFTBalance(contract, queriedAddress, queriedAssetNumber);

    useEffect(()=>{
        window.scrollTo(0, 0);
        (!isLoading && !error)?(
            (currentPath.split('/').length===5 && (props.default===2) && ethers.utils.isAddress(queriedAddress) && (queriedAddress===address || address === Administrator) && !isNaN(Number(queriedAssetNumber)))?
                
                ReactGA.send({ hitType: "pageview", page: {currentPath}, title: shortFormName.concat(" > Print > ",urlPath[props.default]," # ", queriedAssetNumber, " owned by ", queriedAddress) })
                
            :(
                ReactGA.event({
                    category: "Illegal Address",
                    action: "Illegal Address > print > ".concat(currentPath),
                    nonInteraction: true, // optional, true/false
                    transport: "xhr", // optional, beacon/xhr/image
                  })
            )
        ):(<></>)
            
    },[]);
    if (currentPath.split('/').length===5 && (props.default===2) && ethers.utils.isAddress(queriedAddress) && (queriedAddress===address || address === Administrator) && !isNaN(Number(queriedAssetNumber))) {
        return(
            <>
                <Container >
                    <HeaderBlock header = {""} subheader={""} title={true}  key="print"/>
                    <Divider hidden />
                    <Divider hidden />
                    {error?(
                            <FailMessage/>
                            ):(isLoading?(
                                    <Header as='h4' icon textAlign='center' disabled>
                                        <Loader size='huge' active inline='centered' />
                                        <Header.Content>Please wait</Header.Content>
                                    </Header>
                                ):(
                                    (data > 0) ?
                                        <ShowOwnNFT key = {"asset view".concat(props.default)} id = {props.default} profile = {3} targetAdd={queriedAddress} targetAsset={queriedAssetNumber} print={1}/>
                                        :   <FailMessage/>
                                ))}
                </Container>
                <FooterBlock/>
            </>
            
        );
    }
    
    else return (
        <>
            <Container>
                <HeaderBlock header = "" subheader="" title={true}  key="profile-fail"/>
                <FailMessage/>
                
            </Container>
            <FooterBlock/>
        </>
        
    );
}

export default Print;


