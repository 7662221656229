import { memo } from 'react';
import { Image } from 'semantic-ui-react';
import { ThirdwebNftMedia    } from "@thirdweb-dev/react";

const MemorizedImage = memo(function RenderImageOnce({src}) {
  //return <ThirdwebNftMedia  metadata={src} height={360} style={{ width: "100%",  padding:"1px" }}/> ;
  return <Image src={src.animation_url? src.animation_url : src.image} fluid />
});

export default MemorizedImage;
