import React, { useState } from 'react';
import QRCode from 'qrcode.react';
import Web3 from 'web3';
import { Card, Image, Button,Loader, Segment, Icon, Divider, Header, Label, List, Grid, Form, Input, Message, Container } from 'semantic-ui-react';
import {   useContract, useAddress, useNFT } from "@thirdweb-dev/react";
import RenderImageOnce from './RenderImageOnce';
import {taxonomy1, taxonomy2, TTNames, TagStyle, ThemeColor, polygonScanLink, ButtonStyle, Administrator, contractAdd, urlPath, shortFormName, certificateMD, shortFormNameSingular, ButtonStyleOuter} from '../metadata/utils/GetSettings';
import Link from './Link';
import ReactGA from 'react-ga4';


export default function RenderCards(props) {
  const [givenNames, setGivenNames] = useState('');
  const [lastName, setLastName] = useState('');
  const [isMatch, setIsMatch] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  
  const address = useAddress();
  
  const metadata = JSON.parse(JSON.stringify((props.file!=="2")?taxonomy1:taxonomy2));
  const CertMetadata = JSON.parse(JSON.stringify(certificateMD));

  const { contract } = useContract(contractAdd["customers"]);
  const { data: allNFTs, isLoading:isReadingNfts, errorReadingNfts } = useNFT(contract, metadata[props.taktok]["Institute"]);
  const nameRecord = TTNames.find((person)=> person.address===props.target);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitted(true);
    const web3 = new Web3();
    const fullName = (givenNames + ' ' + lastName).toLowerCase();
    const fullNameRecord = (nameRecord["firstName"] + ' '+ nameRecord["secondName"]).toLowerCase();
    const fullNameHash = web3.utils.sha3(fullName.replace(/\s+/g, ' ').trim());
    const fullNameRecordHash = web3.utils.sha3(fullNameRecord.replace(/\s+/g, ' ').trim());

    // Replace "0xUserAddress1" with dynamic user address logic
    const userAddress = "0xUserAddress1";

    if (fullNameRecordHash === fullNameHash) {
      ReactGA.event({
        category: "Asset Visit - name verified".concat(props.id, " owned by ", props.target),
        action: "Asset Visit - name verified".concat(props.id, " owned by ", props.target),
        label: "Asset Visit - name verified".concat(props.id, " owned by ", props.target), // optional
        value: 1, // optional, must be a number
        nonInteraction: false, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });
      setIsMatch(true);
    } else {
      ReactGA.event({
        category: "Asset Visit - name error".concat(props.id, " owned by ", props.target, " - ", fullName),
        action: "Asset Visit - name error".concat(props.id, " owned by ", props.target, " - ", fullName),
        label: "Asset Visit - name error".concat(props.id, " owned by ", props.target, " - ", fullName), // optional
        value: 1, // optional, must be a number
        nonInteraction: false, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });
      setIsMatch(false);
    }
  };
  
  const recordEvent = () =>{
    ReactGA.event({
      category: "Asset Visit - print".concat(props.id, " owned by ", props.target),
      action: "Asset Visit - print".concat(props.id, " owned by ", props.target),
      label: "Asset Visit - print".concat(props.id, " owned by ", props.target), // optional
      value: 1, // optional, must be a number
      nonInteraction: false, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
  }



  return(
    <>
      
      <Card raised key={props.name.concat(props.id)}>
        
        <RenderImageOnce src={props.nftImage}/>
        <Label attached='top left' color = {props.valid?'green':'red' } >
          {props.valid?<>Valid</>:<>Invalid</>}
        </Label>
        
        <Card.Content >
          <Card.Header>
            <>
              {props.valid?<></>:<Icon name = 'warning sign'  color='red'/>}
              {props.name}
            </>
          </Card.Header>    
          <Card.Meta>
            
            Token id: 
            <>
              {props.id}
            </>
            
            
            
            
          </Card.Meta>
          <Card.Description textAlign='center'>
            {props.valid?
              metadata[props.taktok]["Teacher"]!==undefined && nameRecord!==undefined && (props.target===address || address === Administrator)?
                <>
                  {CertMetadata[metadata[props.taktok]["Certificate Type"]]["line1"]} <br/><br/>
                  <b>{nameRecord["prefix"]}. {nameRecord["firstName"]} {nameRecord["secondName"]}</b>
                  <br/>
                  {CertMetadata[metadata[props.taktok]["Certificate Type"]]["topics"].includes("Level") &&
                  metadata[props.taktok]["Occassion"]==="course completion"?
                  <><br/><b>Level {metadata[props.taktok]["level"]} {metadata[props.taktok]["Certificate"]} </b><br/></>:<></>} 
                  <br/>{CertMetadata[metadata[props.taktok]["Certificate Type"]]["line2"]} <br/>
                  {metadata[props.taktok]["Occassion"]==="event"?<>{metadata[props.taktok]["Certificate"]}<br/></>:<></>}
                  <br/>
                  {CertMetadata[metadata[props.taktok]["Certificate Type"]]["topics"].includes("Date")?
                  <><b>on {metadata[props.taktok]["Date"]}  </b><br/></>:<></>} 
                  <br/>
                  {metadata[props.taktok]["Teacher"]} <br/>
                  {metadata[props.taktok]["Teacher Details"]!==undefined?
                    <>
                      {metadata[props.taktok]["Teacher Details"][0]!==undefined?metadata[props.taktok]["Teacher Details"][0]:<></>}
                      {metadata[props.taktok]["Teacher Details"][1]!==undefined?<><br/>{metadata[props.taktok]["Teacher Details"][1]}</>:<></>}
                    </>
                    :<></>
                  }

                </>
                :<>
                  {props.description}
                </>
              :<>
                <Icon name = 'warning sign'  color='red'/>
                This is an invalid token
              </>
            }
          </Card.Description>
          <Card.Description>
            {
              metadata[props.taktok]["Teacher"]!==undefined && 
              metadata[props.taktok]["Certificate"]!==undefined && 
              metadata[props.taktok]["level"] !==undefined 
              ?
              <>
                  <Divider hidden />
                  <Segment placeholder padded>
                    <Header as='h4' textAlign='center'>Achievement Details</Header>
                    <Grid columns={2}> 
                      <Grid.Row>
                        <Grid.Column width ={5}>
                          <Icon name='graduation'  style={{color : ThemeColor}}/> Teacher
                        </Grid.Column>
                        <Grid.Column width ={11}>
                          {metadata[props.taktok]["Teacher"]}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width ={5}>
                          <Icon name='certificate'  style={{color : ThemeColor}}/> Achievement
                        </Grid.Column>
                        <Grid.Column width ={11}>
                          {metadata[props.taktok]["Certificate"]}
                        </Grid.Column>
                      </Grid.Row>
                      {metadata[props.taktok]["level"]!==''?
                          <Grid.Row>
                            <Grid.Column width ={5}>
                              <Icon name='level up'  style={{color : ThemeColor}}/> Level
                            </Grid.Column>
                            <Grid.Column width ={11}>
                              {metadata[props.taktok]["level"]}
                            </Grid.Column>
                          </Grid.Row>
                        :
                          <></>
                      }
                      <Grid.Row>
                        <Grid.Column width ={5}>
                          <Icon name='university'  style={{color : ThemeColor}}/> Issued by
                        </Grid.Column>
                        <Grid.Column width ={11}>
                          {
                              isReadingNfts? <Loader/> : 
                                errorReadingNfts||!allNFTs? <Icon name='exclamation' color='red'/> :
                                  <Link to={'/creator/'.concat(metadata[props.taktok]["Institute"])}>
                                      <Image src={allNFTs.metadata.image} size='mini' floated='left' />
                                      {allNFTs.metadata.name} 
                                      <br/>
                                      {allNFTs.metadata.description!==null?allNFTs.metadata.description:<></>}
                                  </Link>
                            
                          }
                        </Grid.Column>
                      </Grid.Row>
                      {
                        CertMetadata[metadata[props.taktok]["Certificate Type"]]["topics"].map((topic)=>(
                          <>
                            
                              {
                                (topic==="Syllabus")?
                                  <Grid.Row>
                                    <Grid.Column width ={5}>
                                      <Icon name='tasks' style={{color : ThemeColor}}/> Course Syllabus
                                    </Grid.Column>
                                    <Grid.Column width ={11}>
                                      {metadata[props.taktok]["Syllabus"].map((item)=>
                                        <List bulleted>
                                          <List.Item>
                                            {item}
                                          </List.Item>
                                        </List>
                                      )}
                                    </Grid.Column>
                                  </Grid.Row>
                                :
                                  <></>
                              }
                            
                              {
                                (topic==="Signatories")?
                                  <Grid.Row>
                                    <Grid.Column width ={5}>
                                      <Icon name='gavel' style={{color : ThemeColor}}/> Judges
                                    </Grid.Column>
                                    <Grid.Column width ={11}>
                                      {metadata[props.taktok]["Signatories"].map((judge)=>
                                        <List bulleted>
                                          <List.Item>
                                            {judge[0]} - {judge[1]}
                                          </List.Item>
                                        </List>
                                      )}
                                    </Grid.Column>
                                  </Grid.Row>
                                :
                                  <></>
                              }
                           
                            
                              {
                                topic==="Certificate criteria"?
                                  <Grid.Row>
                                    <Grid.Column width ={5}>
                                      <Icon name='balance scale' style={{color : ThemeColor}}/> Certificate criteria
                                    </Grid.Column>
                                    <Grid.Column width ={11}>
                                      {metadata[props.taktok]["Certificate criteria"]}
                                    </Grid.Column>
                                  </Grid.Row>
                                  :
                                    <></>
                              }
                          </>
                        ))
                      }  
                      
                      <Grid.Row>
                        <Grid.Column width ={5}>
                          <Icon name='thumbtack' style={{color : ThemeColor}}/> Blockchain Transaction
                        </Grid.Column>
                        <Grid.Column width ={11}>
                          <a href={polygonScanLink.concat("tx/",props.hash)} target="_blank">
                              <QRCode 
                                  value={polygonScanLink.concat("tx/",props.hash)} 
                                  size={60} 
                                  fgColor={ThemeColor}
                              />
                              <br/>Blockchain Transaction
                          </a>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment>
                  
              </>
              :
              <></>
              }
          <Divider hidden />
          {(props.target!==address && address !== Administrator) ? 
            <Segment  padded>
              
              <Header as='h3' textAlign='center'>Verify this certificate</Header>
              <Divider hidden />
              <Grid columns={3} textAlign='center' stackable divided='vertically'> 
                
                <Grid.Row>
                  <Grid.Column width ={3} textAlign='center'>Step 1</Grid.Column>
                  <Grid.Column width ={10} textAlign='center'>Is the token valid?</Grid.Column>
                  <Grid.Column width ={3} textAlign='center'>{props.valid?<Icon name = 'thumbs up' size='big' color='green'/>:<Icon name = 'thumbs down' size='big' color='red'/>}</Grid.Column>
                </Grid.Row>
                
                <Grid.Row>
                  <Grid.Column width ={3} textAlign='center'>Step 2</Grid.Column>
                  <Grid.Column width ={10} textAlign='center'>
                    Is the name on the certificate valid?
                    <Divider hidden />
                    <Form onSubmit={handleSubmit} success={isMatch } error={!isMatch}>
                      <Form.Field>
                        <label>Given Names</label>
                        <Input
                          placeholder='Given Names'
                          value={givenNames}
                          onChange={(e) => {setGivenNames(e.target.value);setIsSubmitted(false)}}
                        />
                      </Form.Field>
                      <Form.Field>
                        <label>Last Name</label>
                        <Input
                          placeholder='Last Name'
                          value={lastName}
                          onChange={(e) => {setLastName(e.target.value);setIsSubmitted(false)}}
                        />
                      </Form.Field>
                      {window.innerWidth>768?
                        <Button 
                          type='submit'
                          disabled={(givenNames==='' || lastName==='')}
                          animated='fade' 
                          size='medium' 
                          fluid 
                          style={ButtonStyle}
                        >
                          <Button.Content visible  style={{color: ThemeColor}}>Verify Name</Button.Content>
                          <Button.Content hidden style={{color: ThemeColor}}><Icon name='detective' /></Button.Content>
                          
                        </Button>
                      :
                        <Button as='div'  labelPosition='left' type='submit' 
                          disabled={(givenNames==='' || lastName==='')} 
                          size='medium' fluid  >
                          <Label basic style={ButtonStyleOuter} pointing='right'>
                            <Icon name='detective' />
                          </Label>
                          <Button  fluid style={ButtonStyle} size='medium'> Verify Name</Button>
                        </Button>
                      }
                      
                    </Form>
                  </Grid.Column>
                  <Grid.Column width ={3} textAlign='center'>
                    <>
                      {(isSubmitted && isMatch)?<Icon name = 'thumbs up' size='big' color='green'/>:(givenNames!=='' && lastName!=='' && isSubmitted)?<Icon name = 'thumbs down' size='big' color='red'/>:<></>}
                      
                    </>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
              {(isSubmitted && isMatch)?<Label color='green' ribbon>Name Verified</Label>:(givenNames!=='' && lastName!=='' && isSubmitted)?<Label color='red' ribbon>Name Verification Failed - check spelling or possible fraud?</Label>:<></>}
            </Segment>
            :<></>}
            
            
            
              
          </Card.Description>
          <Divider hidden />
          
         
          <Card.Meta>
            

            {metadata[props.taktok]["tax"].map((taktokTag)=>(
              <>
                
                <Header as='h5' >WEF Global Skills Taxonomy</Header>
                <Label.Group tag>
                {(taktokTag["ST"]["L1"]!=="")?
                  (<Label key={"STL1".concat(props.id)} style={TagStyle}>
                    L1
                    <Label.Detail>
                        {taktokTag["ST"]["L1"]}
                    </Label.Detail>
                  </Label>)
                : (<></>)}
                {(taktokTag["ST"]["L2"]!=="")?
                  (<Label key={"STL2".concat(props.id)} style={TagStyle}>
                    L2
                    <Label.Detail>
                        {taktokTag["ST"]["L2"]}
                    </Label.Detail>
                  </Label>)
                : (<></>)}
                {(taktokTag["ST"]["L3"]!=="")?
                  (<Label key={"STL3".concat(props.id)} style={TagStyle}>
                    L3
                    <Label.Detail>
                        {taktokTag["ST"]["L3"]}
                    </Label.Detail>
                  </Label>)

                : (<></>)}
                {(taktokTag["ST"]["L4"]!=="")?
                  (<Label key={"STL4".concat(props.id)} style={TagStyle}>
                    L4
                    <Label.Detail>
                        {taktokTag["ST"]["L4"]}
                    </Label.Detail>
                  </Label>)
                : (<></>)}
                </Label.Group>
                  
                
                
                <Header as='h5' >WEF Education 4.0 Taxonomy</Header>
                
                <Label.Group tag>
                {(taktokTag["ET"]["L1"]!=="")?
                  (
                    <Label key={"ETL1".concat(props.id)} style={TagStyle}>
                        L1
                        <Label.Detail>
                        {taktokTag["ET"]["L1"]}
                        </Label.Detail>
                    </Label>
                  ): (<></>)}
                {(taktokTag["ET"]["L2"]!=="")?
                (
                  <Label key={"ETL2".concat(props.id)} style={TagStyle}>
                        L2
                        <Label.Detail>
                            {taktokTag["ET"]["L2"]}
                        </Label.Detail>
                  </Label>
                ): (<></>)}
                {(taktokTag["ET"]["L3"]!=="")?
                (
                  <Label key={"ETL3".concat(props.id)} style={TagStyle}>
                        L3
                        <Label.Detail>
                            {taktokTag["ET"]["L3"]}
                        </Label.Detail>
                  </Label>
                ): (<></>)}
                </Label.Group>
                  
              </>
              ))
            }
          </Card.Meta>
        </Card.Content>
        
        <Card.Content extra textAlign = 'center'>
          
          <Grid stackable columns={props.valid && props.target===address?2:1}> 
            <Grid.Row>
              <Grid.Column>
                <Link to= {address===props.target?"/my".concat(shortFormName,"/",urlPath[2]):"/profile/".concat(urlPath[2],"/",props.target)}>
                  {window.innerWidth>768?
                    <Button animated='fade' size='medium' fluid style={ButtonStyle}>
                      <Button.Content visible  style={{color: ThemeColor}}>{address===props.target?"All your Award ".concat(shortFormName):"All the ".concat(shortFormName, " in this wallet")}</Button.Content>
                      <Button.Content hidden style={{color: ThemeColor}}><Icon name='chevron circle left' /></Button.Content>
                    </Button>
                  :
                    <Button as='div'  labelPosition='left' size='medium' fluid   >
                      <Label basic style={ButtonStyleOuter} pointing='right'>
                        <Icon name='chevron circle left' />
                      </Label>
                      <Button basic style={ButtonStyle} fluid>{address===props.target?"All your Award ".concat(shortFormName):"All the ".concat(shortFormName, " in this wallet")}</Button>
                    </Button>
                  }
                </Link>
              </Grid.Column>  
              {props.valid && (props.target===address || address === Administrator)?
                <Grid.Column>
                  <Link to= {"/print/".concat(urlPath[2],"/",props.target,"/",props.id)}>
                    {window.innerWidth>768?
                      <Button animated='fade' size='medium' fluid style={ButtonStyle} onClick={recordEvent}>
                        <Button.Content visible style={{color: ThemeColor}}>Download your {shortFormNameSingular}</Button.Content>
                        <Button.Content hidden style={{color: ThemeColor}}><Icon name='download' /></Button.Content>
                      </Button>
                    :
                      <Button as='div'  labelPosition='left' size='medium' fluid   >
                        <Label basic style={ButtonStyleOuter} pointing='right'>
                          <Icon name='download' />
                        </Label>
                        <Button basic style={ButtonStyle} fluid>Download your {shortFormNameSingular}</Button>
                      </Button>
                    }
                  </Link>
                </Grid.Column>
                :<></>}
            </Grid.Row>  
          </Grid>
        </Card.Content> 
    </Card>
    </>            
  );
}
