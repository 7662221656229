import React, { useEffect } from 'react';
import {   Header, Divider, Button, Icon, Container } from 'semantic-ui-react';



function Fail(){
  
  return (
    <Container>
      
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Header as='h4' icon textAlign='center' disabled>
        <Icon name='exclamation' color='red'/><Header.Content>Incorrect address... please check the address of the profile you want to see</Header.Content>
        
        
      </Header>
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      <Divider hidden />
      
    </Container>
      
  );
}

export default Fail;