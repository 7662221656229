import React, { useState } from 'react';
import { useContract, useAddress, useNFTs, useMetadata } from "@thirdweb-dev/react";
import { Card, Icon, Header, Table,  Loader, Image, List, Divider, Container } from 'semantic-ui-react';

import RenderPagenation from './renderPagenation';
import RenderImageOnce from './RenderImageOnce';
import FetchEvents from './FetchHolders';
import {taxonomy1, taxonomy2, contractAdd, excludeList, creatorMD, shortFormName,certificateMD,shortFormNameSingular,tokenFamilyName} from '../metadata/utils/GetSettings';
import Link from './Link';
import IntelligentWaitingListForm from './IntelligentWaitingListForm';



function ShowNFTGallery(props){
  const [errors, recordErrors] = useState([]);
  const [updated, update]= useState(false);
  

  const { contract } = useContract(contractAdd[props.id]);
  const taxMetadata = {1: JSON.parse(JSON.stringify(taxonomy1)),2: JSON.parse(JSON.stringify(taxonomy2))};
  const cMD = JSON.parse(JSON.stringify(creatorMD));
  const CertMetadata = JSON.parse(JSON.stringify(certificateMD));
  const { data: allNFTs, isLoading:isReadingNfts, errorReadingNfts } = useNFTs(contract);
  const { data: metadata, isLoading: loadingMetadata, errorMetadata } = useMetadata(contract);
  let tempp = [];
  const [selected, setFilter] = useState(
    {
      "ST" : {"L1": new Set(),"L2": new Set(),"L3": new Set(),"L4": new Set()},
      "ET" : {"L1": new Set(),"L2": new Set(),"L3": new Set()},
      "competancy" : new Set()
    }
  );

  const updateTag = (tax,level,newValue,operation) => {
    const set2 = selected;
    tax==="competancy"?(operation===1?set2[tax].add(newValue):set2[tax].delete(newValue)):(
      operation===1?set2[tax][level].add(newValue):set2[tax][level].delete(newValue)
    )
    
    setFilter(set2);
    update(!updated);
    setFilter(set2);
 
  }

  const applyFilter = (tax,level,selectedTag) => {
    //console.log(selected);
    //console.log(level, selectedTag);
    tax==="competancy"?(selected[tax].has(selectedTag)?updateTag(tax,level,selectedTag,0):updateTag(tax,level,selectedTag,1)):(
      selected[tax][level].has(selectedTag)?updateTag(tax,level,selectedTag,0):updateTag(tax,level,selectedTag,1)
    );
  }

  
  const determineFilter = (file, token, type, level) => {
    let lST = false;
    let lvl = {1:"L1", 2:"L2", 3:"L3", 4:"L4"};
    taxMetadata[file][token]["tax"].map((txDetail)=>( 
      lST=lST||selected[type][lvl[level]].has(txDetail[type][lvl[level]])
    ))
    return lST;
  }

  const determineLevel = (file, token) => {
    return selected["competancy"].has(taxMetadata[file][token]["level"])
  }


  return (
    <div>

      <Divider hidden />
      <Divider hidden />
      {(props.id===0)?(
        <Header as='h4' textAlign='center' key={'select'.concat(props.id)}>
          <Header.Content>
              Please select an {shortFormNameSingular} family
          </Header.Content>
        </Header>
      ):(
        
            (isReadingNfts || loadingMetadata )? (
              <div>
                <Header as='h4' icon textAlign='center' disabled key={'wait'.concat(props.id)}> 
                  <Loader size='huge' active inline='centered' />
                  <Header.Content>Please wait</Header.Content>
                </Header>
              </div>
            ) : (
                  (errorReadingNfts || errorMetadata)?(
                    <Header as='h4' icon textAlign='center' disabled key={'error'.concat(props.id)}> 
                        <Icon name='exclamation' color='red'/><Header.Content>Error in reading from the blockchain. Please try again later... Sorry!</Header.Content>
                    </Header>
                  ):(
                      <>
                        
                        {(allNFTs.length === 0)? (
                          (props.purpose!=="creator")?
                            <Header as='h2' icon textAlign='center' key={'sorry'.concat(props.id)}>
                              <Icon name='thumbs down' />
                                So sorry
                              <Header.Subheader>
                                We are still loading {shortFormName}...
                              </Header.Subheader>
                            </Header>
                          :<></>
                        ):(
                          <div>
                            {(props.purpose!=="creator")?
                              <Header as='h2' textAlign='center' key={'title image'.concat(props.id)}>
                                <Image src = {metadata.image} size = 'massive' circular spaced />
                                {metadata.name}
                              </Header>
                              :<></>}
                            {
                              /*
                                <Grid relaxed stackable columns={2} verticalAlign='middle' key={'grid'.concat(props.id)}>
                                  <Grid.Column>
                                    <Segment padded='very' vertical textAlign='center' key={'segment1'.concat(props.id)}>
                                      <Header as='h4' textAlign='center'color = 'grey' key={'title'.concat(props.id)}>
                                        <Header.Content>
                                          <Icon name='quote left' key={'quote left icon'.concat(props.id)}/>
                                          <br /><br />
                                          {quotes[props.id][0]}
                                          <br />
                                          <br/>
                                          {quotes[props.id][1]}
                                          <br /><br />
                                          <Icon name='quote right' key={'quote right icon'.concat(props.id)}/>
                                        </Header.Content>
                                      </Header>
                                    </Segment>
                                  </Grid.Column>
                                  <Grid.Column>
                                    <Segment vertical textAlign='center' key={'segment2'.concat(props.id)}>
                                      <Header as='h3' textAlign='center' color = 'orange' key={'quote'.concat(props.id)}>
                                        <Header.Content>
                                          {quotes[props.id][2]}
                                        </Header.Content>
                                      </Header>
                                    </Segment>
                                  </Grid.Column>
                                </Grid>
                              */
                            }
                            

                            {(props.id===2)?(
                              (props.purpose!=="creator")?
                                <>
                                  <Header as='h3' textAlign='center' key={'Warning'.concat(props.id)}>
                                    The tokens in this {shortFormName} family is made to order. 
                                    
                                    <Divider hidden />
                                    {/*
                                      {"To use ".concat(tokenFamilyName[props.id]," ",shortFormName, " join our waiting list")}
                                      <IntelligentWaitingListForm context="waiting list"/>
                                      <Divider hidden />
                                    
                                    
                                        
                                    
                                    <Divider hidden />*/  }
                                    Here are some of the tokens in the '{metadata.name}' family...
                                    <Header.Subheader>Filter tokens by selecting tags as needed</Header.Subheader>
                                  </Header>
                                  
                                </>
                              :
                              <>
                                
                                
                                <Header as='h3' textAlign='center' key={'list header'.concat(props.id)}>
                                    Awards issued
                                </Header>
                              </>
                              
                            ):
                            (
                              <>
                                <Header as='h3' textAlign='center' key={'list header'.concat(props.id)}>
                                      Here are all the tokens in the '{metadata.name}' family...
                                      <Header.Subheader>Filter tokens by selecting tags as needed</Header.Subheader>
                                </Header>
                                
                              </>
                            )}

                            
                            <Divider hidden />
                            {(props.purpose!=="creator")?
                              <>
                                
                                {allNFTs.map((nft) => (
                                  ((!(excludeList[props.id].includes(parseInt(nft.metadata.id,10)))&& nft.metadata.attributes!== undefined)?(

                                      (
                                        (
                                          (selected.ST.L1.size === 0) &&
                                          (selected.ST.L2.size === 0) &&
                                          (selected.ST.L3.size === 0) &&
                                          (selected.ST.L4.size === 0) &&
                                          (selected.ET.L1.size === 0) &&
                                          (selected.ET.L2.size === 0) &&
                                          (selected.ET.L3.size === 0) &&
                                          (selected.competancy.size === 0)
                                        ) ||
                                        (
                                          (selected.ST.L1.size > 0 && determineFilter(nft.metadata.attributes[4]["value"].toString(), nft.metadata.attributes[5]["value"].toString(), "ST", 1)) ||
                                          (selected.ST.L2.size > 0 && determineFilter(nft.metadata.attributes[4]["value"].toString(), nft.metadata.attributes[5]["value"].toString(), "ST", 2)) ||
                                          (selected.ST.L3.size > 0 && determineFilter(nft.metadata.attributes[4]["value"].toString(), nft.metadata.attributes[5]["value"].toString(), "ST", 3)) ||
                                          (selected.ST.L4.size > 0 && determineFilter(nft.metadata.attributes[4]["value"].toString(), nft.metadata.attributes[5]["value"].toString(), "ST", 4)) ||
                                          (selected.ET.L1.size > 0 && determineFilter(nft.metadata.attributes[4]["value"].toString(), nft.metadata.attributes[5]["value"].toString(), "ET", 1)) ||
                                          (selected.ET.L2.size > 0 && determineFilter(nft.metadata.attributes[4]["value"].toString(), nft.metadata.attributes[5]["value"].toString(), "ET", 2)) ||
                                          (selected.ET.L3.size > 0 && determineFilter(nft.metadata.attributes[4]["value"].toString(), nft.metadata.attributes[5]["value"].toString(), "ET", 3)) ||
                                          (selected.competancy.size > 0 && determineLevel(nft.metadata.attributes[4]["value"].toString(), nft.metadata.attributes[5]["value"].toString()))
                                        )
                                      )?
                                          
                                          
                                            tempp.push(nft)>0?<></>:<></>
                                            
                                          
                                           
                                          
                                        
                                    :
                                    (
                                      <></>
                                    )


                                  ):(
                                    <></>
                                  ))

                                  ))}
                                  
                                   
                                  <RenderPagenation
                                    items = {tempp}
                                    contractImage = {metadata.image}
                                    contractName = {metadata.name}
                                    contract = {contractAdd[props.id]}
                                    selectedFilters = {selected}
                                    filter = {applyFilter}
                                    collection = {props.id}
                                    key = {props.id}
                                  />
                                
                              </>
                              :<>
                                  <Table stackable celled striped>
                                    <Table.Header>
                                      <Table.Row>
                                        <Table.HeaderCell textAlign='center' width={3}>Course</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center' width={4}>Details</Table.HeaderCell>
                                        <Table.HeaderCell textAlign='center' width={2}># Awards Issued</Table.HeaderCell>
                                        {/*<Table.HeaderCell textAlign='center'>Awardees</Table.HeaderCell>*/}
                                      </Table.Row>
                                    </Table.Header>

                                    <Table.Body>
                                      
                                      {allNFTs.map((nft) => (
                                        (!(excludeList[props.id].includes(parseInt(nft.metadata.id,10))) && 
                                            nft.metadata.attributes!== undefined && 
                                            taxMetadata[nft.metadata.attributes[4]["value"].toString()][nft.metadata.attributes[5]["value"].toString()]["Institute"]===props.view)?(
                                              <>
                                                
                                                <Table.Row  key = {nft.metadata.id}>
                                                  <Table.Cell textAlign='center' key = {"C1".concat(nft.metadata.id)} ><RenderImageOnce src={nft.metadata} size={"small"} key={"C1.5".concat(nft.metadata.id)}/><br/>{nft.metadata.name}</Table.Cell>
                                                  <Table.Cell  key = {"C4".concat(nft.metadata.id)} >
                                                    {
                                                      CertMetadata[taxMetadata[nft.metadata.attributes[4]["value"].toString()][nft.metadata.attributes[5]["value"].toString()]["Certificate Type"]]["topics"].includes("Syllabus")?
                                                        <>
                                                          <Header as='h5' textAlign='center' key='Syllabus'>
                                                            Syllabus
                                                          </Header>
                                                          {taxMetadata[nft.metadata.attributes[4]["value"].toString()][nft.metadata.attributes[5]["value"].toString()]["Syllabus"].map((item)=>
                                                            <List bulleted>
                                                              <List.Item>
                                                                {item}
                                                              </List.Item>
                                                            </List>
                                                          )}
                                                        </>
                                                        : CertMetadata[taxMetadata[nft.metadata.attributes[4]["value"].toString()][nft.metadata.attributes[5]["value"].toString()]["Certificate Type"]]["topics"].includes("Signatories")?
                                                            <>
                                                              <Header as='h5' textAlign='center' key='judges'>
                                                                Judges
                                                              </Header>
                                                              
                                                              {taxMetadata[nft.metadata.attributes[4]["value"].toString()][nft.metadata.attributes[5]["value"].toString()]["Signatories"].map((item)=>
                                                                <List bulleted>
                                                                  <List.Item>
                                                                    {item}
                                                                  </List.Item>
                                                                </List>
                                                              )}
                                                            </>:<></>
                                                    }
                                                  </Table.Cell>
                                                  <Table.Cell textAlign='center' key = {"C2".concat(nft.metadata.id)}>{nft.supply}</Table.Cell>
                                                  {/*<Table.Cell key = {"C3".concat(nft.metadata.id)}><FetchEvents id={props.id} tId={Number(nft.metadata.id)}/></Table.Cell>*/}
                                                </Table.Row>
                                              </>
                                            ):<></>
                                      ))}
                                    </Table.Body>
                                  </Table>
                              </>
                            }
                        </div>
                      )}

                  </>

                )
              )
            
          )
        }


    </div>);

}

export default ShowNFTGallery;
