import React from 'react';
import { Header, Image, Divider, Grid, Icon } from 'semantic-ui-react';
import LogoImg from '../images/tt.png';
import Link from './Link';

const CustomStyle = {
  backgroundColor: 'white',
  color: '#0044AA',
  
}

const FooterBlock = () => (
  < >
    
    <Divider hidden />
    <Divider hidden />
    <Divider hidden />
    <Divider hidden />
    <Divider hidden />
    <Divider hidden />
    
    <Divider  />
    <Divider hidden />
    <Grid >
      
      <Grid.Column width={5} textAlign='center'>
        <Header as='h3' >
          <a href="https://www.linkedin.com/company/skill-accolades/" target="_blank">
            <Image circular src={LogoImg} size='mini' centered/>
            <Header.Subheader>
              Skill Accolades
            </Header.Subheader>
          </a>
        </Header>
      </Grid.Column>
      <Grid.Column width={6} textAlign='center'>
        <Header as='h3'  >
          <Link to='/transparency'>
            <Icon name='cogs' size={'large'} style = {CustomStyle}/>
            <Header.Subheader>
              Transparency
            </Header.Subheader>
          </Link>
        </Header>
      </Grid.Column>
      <Grid.Column width={5} textAlign='center'>
        <Header as='h3'  >
          <Link to='/contact'>
            <Icon name='write' size={'large'} style = {CustomStyle}/>
            <Header.Subheader>
              Get in touch
            </Header.Subheader>
          </Link>
        </Header>
          
      </Grid.Column>
      
    </Grid>
  </>
)

export default FooterBlock;
