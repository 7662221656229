import React, { useState, memo } from 'react';
import { Card,  Button,Loader, Input, Icon, Divider, Label, Header } from 'semantic-ui-react';
import { useClaimNFT, useContract, useClaimConditions, useAddress, useNFTs} from "@thirdweb-dev/react";
import RenderImageOnce from './RenderImageOnce';
import taxonomy1 from '../metadata/1.json';
import taxonomy2 from '../metadata/2.json';
import { ethers } from 'ethers';
import ReactGA from 'react-ga4';

export default function RenderAllCards(props) {
  const [count, setCount] = useState(0);
  
  


  const address = useAddress();
  const [toAddress, setToAddress] = useState('');
  const [submission, setSubmission] = useState('Ready');
  const [errors, recordErrors] = useState('');
  const metadata = JSON.parse(JSON.stringify((props.file==='1')?taxonomy1:taxonomy2));



  const { contract } = useContract(props.contract);
  //const { mutate: claimNft, isClaiming, error } = useClaimNFT(contract);

  //const { data: allNFTs, isLoading:isReadingNfts, error } = useNFTs(contract);
  const { data: claimConditions, isFetching, error1 } =useClaimConditions(contract, props.id);

  const handleSubmission = async () =>{
    setSubmission('submitted');
    try {
      const response = await contract.erc1155.claimTo(toAddress, props.id, 1);
      setSubmission('success');
      ReactGA.event({
        category: "Mint stats",
        action: "Mint of ".concat(props.id, " in ",props.contractName," : ", submission),
        label: "Mint of ".concat(props.id, " in ",props.contractName," : ", submission), // optional
        value: 1, // optional, must be a number
        nonInteraction: false, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      });

    } catch (err) {
      recordErrors(err);

      setSubmission('failure');
      (err.reason==="user rejected transaction")?(
        setSubmission('failure')
      ):(
          (err.cancelled)?(
            setSubmission('cancelled')
          ):(
            setSubmission('success')
          )
      );
      
    }
  }

  const handleClick = () =>{
    ((submission === 'success')||(submission === 'failure')||(submission === 'cancelled'))?(
      setSubmission('Ready')
    ):(
      <></>
    );
    setCount(count+1);
  }

  const handleChange = (event) =>{
    setToAddress(event.target.value);
  }

  const updateTag = (tax,level,newValue,status) =>{
    
    props.filter(tax,level,newValue);
    ReactGA.event({
      category: "Tags on ".concat(props.name),
      action: tax.concat(" > ",level," : ", newValue),
      label: newValue, // optional
      value: status?1:0, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });

  }

  const TagStyle = {
    backgroundColor: '#0044AA',
    color: 'white',
    
  }

  return(
    <Card raised key={props.name.concat(props.id)}>

      
      

      <RenderImageOnce src={props.nftImage} key={props.name.concat(props.id)}/>

      <Card.Content key={props.name.concat(props.id,"content part1")}>
        <Card.Header>{props.name}</Card.Header>
        <Card.Meta key={props.name.concat(props.id,"part1")}>
          Token id: {props.id}
            <Label.Group>
                {(metadata[props.taktok]["level"]!=="")?
                  (<Label key={"Level".concat(props.id)} as ="a"
                  onClick={()=>updateTag("competancy","",metadata[props.taktok]["level"],props.selectedFilters.competancy.has(metadata[props.taktok]["level"]))}>
                            <Icon name={props.selectedFilters.competancy.has(metadata[props.taktok]["level"])?'check circle':'circle outline'} />
                            <Icon name='level up' />
                            Level
                            <Label.Detail>
                              {metadata[props.taktok]["level"]}
                            </Label.Detail>
                  </Label>)
                : (<></>)}
            </Label.Group>
        </Card.Meta>
        <Card.Description textAlign = 'center'>
          {props.description}
        </Card.Description>
        <Divider hidden />
        <Divider  hidden/>
        <Card.Meta key={props.name.concat(props.id,"part2")}>
          
          
            {metadata[props.taktok]["tax"].map((taktokTag)=>(
              <>
                <Divider />
                <Header as='h5' >WEF Global Skills Taxonomy</Header>
                <Label.Group tag>
                {(taktokTag["ST"]["L1"]!=="")?
                  (<Label key={"STL1".concat(props.id)} style={TagStyle} as ="a"
                          onClick={()=>updateTag("ST","L1",taktokTag["ST"]["L1"],props.selectedFilters.ST.L1.has(taktokTag["ST"]["L1"]))}
                          >
                            
                            <Icon name={props.selectedFilters.ST.L1.has(taktokTag["ST"]["L1"])?'check circle':'circle outline'} />
                            L1
                            <Label.Detail>
                              {taktokTag["ST"]["L1"]}
                            </Label.Detail>
                  </Label>)
                : (<></>)}
                {(taktokTag["ST"]["L2"]!=="")?
                  (<Label key={"STL2".concat(props.id)} style={TagStyle} as ="a"
                          onClick={()=>updateTag("ST","L2",taktokTag["ST"]["L2"],props.selectedFilters.ST.L2.has(taktokTag["ST"]["L2"]))}
                          >
                            
                            <Icon name={props.selectedFilters.ST.L2.has(taktokTag["ST"]["L2"])?'check circle':'circle outline'} />
                            L2
                            <Label.Detail>
                              {taktokTag["ST"]["L2"]}
                            </Label.Detail>
                  </Label>)

                : (<></>)}
                {(taktokTag["ST"]["L3"]!=="")?
                  (<Label key={"STL3".concat(props.id)} style={TagStyle} as ="a"
                          onClick={()=>updateTag("ST","L3",taktokTag["ST"]["L3"],props.selectedFilters.ST.L3.has(taktokTag["ST"]["L3"]))}
                          >
                            
                            <Icon name={props.selectedFilters.ST.L3.has(taktokTag["ST"]["L3"])?'check circle':'circle outline'} />
                            L3
                            <Label.Detail>
                              {taktokTag["ST"]["L3"]}
                            </Label.Detail>
                  </Label>)

                : (<></>)}
                {(taktokTag["ST"]["L4"]!=="")?
                  (<Label key={"STL4".concat(props.id)} style={TagStyle} as ="a"
                          onClick={()=>updateTag("ST","L4",taktokTag["ST"]["L4"],props.selectedFilters.ST.L4.has(taktokTag["ST"]["L4"]))}
                          >
                            
                            <Icon name={props.selectedFilters.ST.L4.has(taktokTag["ST"]["L4"])?'check circle':'circle outline'} />
                            L4
                            <Label.Detail>
                              {taktokTag["ST"]["L4"]}
                            </Label.Detail>
                  </Label>)
                : (<></>)}
                </Label.Group>
                  
                
                <Divider hidden />
                <Header as='h5' >WEF Education 4.0 Taxonomy</Header>
                
                <Label.Group tag>
                {(taktokTag["ET"]["L1"]!=="")?
                  (
                    <Label key={"ETL1".concat(props.id)} style={TagStyle} as ="a"
                            onClick={()=>updateTag("ET","L1",taktokTag["ET"]["L1"],props.selectedFilters.ET.L1.has(taktokTag["ET"]["L1"]))}
                            >
                              
                              <Icon name={props.selectedFilters.ET.L1.has(taktokTag["ET"]["L1"])?'check circle':'circle outline'} />
                              L1
                              <Label.Detail>
                                {taktokTag["ET"]["L1"]}
                              </Label.Detail>
                    </Label>
                  ): (<></>)}
                {(taktokTag["ET"]["L2"]!=="")?
                (
                  <Label key={"ETL2".concat(props.id)} style={TagStyle} as ="a"
                          onClick={()=>updateTag("ET","L2",taktokTag["ET"]["L2"],props.selectedFilters.ET.L2.has(taktokTag["ET"]["L2"]))}
                          >
                            
                            <Icon name={props.selectedFilters.ET.L2.has(taktokTag["ET"]["L2"])?'check circle':'circle outline'} />
                            L2
                            <Label.Detail>
                              {taktokTag["ET"]["L2"]}
                            </Label.Detail>
                  </Label>
                ): (<></>)}
                {(taktokTag["ET"]["L3"]!=="")?
                (
                  <Label key={"ETL3".concat(props.id)} style={TagStyle} as ="a"
                          onClick={()=>updateTag("ET","L3",taktokTag["ET"]["L3"],props.selectedFilters.ET.L3.has(taktokTag["ET"]["L3"]))}
                          >
                            
                            <Icon name={props.selectedFilters.ET.L3.has(taktokTag["ET"]["L3"])?'check circle':'circle outline'} />
                            L3
                            <Label.Detail>
                              {taktokTag["ET"]["L3"]}
                            </Label.Detail>
                  </Label>
                ): (<></>)}
                </Label.Group>
                  
              </>
              ))
            }


            
        

        </Card.Meta>
      </Card.Content>

      <Card.Content extra textAlign = 'center' key={props.name.concat(props.id,"content part2")}>
          {((count%2===1)&&(props.collection!==2))?(
            <>

              <Input
                action={
                  <Button
                    icon= 'send'
                    color = 'green'
                    disabled = {((toAddress==='')||(toAddress===address)||(submission==='submitted')||(!ethers.utils.isAddress(toAddress)))?(true):(false)}
                    onClick={handleSubmission}
                    key = "send"
                    />}
                label={{ icon: 'address book outline' }}
                labelPosition='left corner'
                placeholder='To address...'
                defaultValue={toAddress}
                disabled = {(submission==='submitted')?(true):(false)}
                onChange = {handleChange}
                fluid
                key = {'address'}
              />
            </>
            ):(<></>)}
            {((count%2===1)||(submission==='success')||(submission === 'failure')||(submission === 'cancelled'))?(
              < >
              {(submission==='success')?(
                < >
                  <Icon name = 'thumbs up' size='small' color='green' key={'Successfully minted'.concat(props.id)}/> Successfully transferred
                </ >):(
                          ((submission==='failure')||(submission==='cancelled'))?(
                            < >
                              <Icon name = 'thumbs down' size='small' color='red' key={'Try again'.concat(props.id)}/> Transfer {submission}... please try again
                            </ >):(
                                      (submission==='submitted')?(
                                        < >
                                          Transfer to {toAddress} in progress <Loader size='small' active inline />
                                        </ >):(
                                                  (toAddress===address)?(
                                                    < >
                                                      <Icon name = 'warning' size='small' color='orange' key={'Own minting'.concat(props.id)}/>Transfer to your own wallet
                                                    </ >):(
                                                              (toAddress==='')?(
                                                                'Please specify the target address'):(
                                                                  (!ethers.utils.isAddress(toAddress))?
                                                                    (
                                                                      < >
                                                                        <Icon name = 'warning sign' size='small' color='red' key={'double check'.concat(props.id)}/>Invalid address - please check and enter a valid address
                                                                      </ >
                                                                    ):(
                                                                        <>
                                                                          <Icon name = 'warning sign' size='small' color='red' key={'double check'.concat(props.id)}/>Action cannot be undone - please double check the target address before sending
                                                                        </>
                                                                    )
                                                                  )
                                                                )
                                                )
                                    )
                          )
                  }

            </ >
          ):(< ></ >)}
          {((submission==='submitted')||(props.collection===2))?(< ></ >):(
            < >
              <br/>

              <Button
                color = {((address===undefined)||((!isFetching)?(claimConditions.length===0):(false)))?('grey'):(((count%2===1))?('red'):('green'))}
                disabled = {((address===undefined)||((!isFetching)?(claimConditions.length===0):(false)))?(true):(false)}
                onClick = {handleClick}
                key = {'sendRed'}
                >
                {((address===undefined)?(
                  "Please connect your wallet to get this"
                ):(
                    (count%2===0)?(
                        (isFetching)?(
                          < >
                          Send this for <Loader size='mini' active inline='centered' />
                          </ >
                          ):(
                              (claimConditions.length>0)?(
                                ((submission === 'failure')||(submission === 'cancelled'))?(
                                  "Please try again for ".concat(
                                    (claimConditions[0].currencyMetadata.displayValue).concat(
                                      " ",claimConditions[0].currencyMetadata.name)
                                    )
                                ):(
                                  (submission === 'success')?(
                                    "Send again for ".concat(
                                      (claimConditions[0].currencyMetadata.displayValue).concat(
                                        " ",claimConditions[0].currencyMetadata.name)
                                      )
                                    ):(
                                      "Send this for ".concat(
                                        (claimConditions[0].currencyMetadata.displayValue).concat(
                                          " ",claimConditions[0].currencyMetadata.name)
                                        )
                                  ))

                                ):(
                                  "Coming soon...join our mailing list"
                                )
                          )

                    ):(

                        ((submission==='success')||(submission === 'failure')||(submission === 'cancelled'))?(setCount(count+1)):(< >Cancel</ >)

                    )

                ))}</Button>

              {(((submission!=='success')&&(submission !== 'failure')&&(submission !== 'cancelled'))&&(count%2===0))?(< ><p>Limited # left</p></ >):(< ></ >)}
            </ >
          )}

      </Card.Content>
    </Card>
  );
}
