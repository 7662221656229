import React, { useState, useEffect  } from 'react';
import { Dropdown, Pagination, Card, Container, Divider, Grid } from 'semantic-ui-react';
import RenderCards from './renderAllCards';
import {taxonomy1, taxonomy2} from '../metadata/utils/GetSettings';

function PaginatedList(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(6);
  const [selectedTag, setSelectedTag] = useState('');
  const [tagOptions, setTagOptions] = useState([]);
  const taxMetadata = {1: JSON.parse(JSON.stringify(taxonomy1)),2: JSON.parse(JSON.stringify(taxonomy2))};
  

  useEffect(() => {
    
    const allTags = new Set();
    
    props.items.map((item)=>(taxMetadata[item.metadata.attributes[4]["value"]][item.metadata.attributes[5]["value"]]["tags"]!==undefined?
      taxMetadata[item.metadata.attributes[4]["value"]][item.metadata.attributes[5]["value"]]["tags"].map(arraEl => allTags.add(arraEl))
      :<></>))
    
    const options = Array.from(allTags).map(tag => ({
      key: tag,
      text: tag.charAt(0).toUpperCase() + tag.slice(1),
      value: tag
    }));

    setTagOptions(options);
  }, []);

  // Calculate the indices of the first and last items on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const filteredData = props.items.filter(element =>
    !selectedTag || (taxMetadata[element.metadata.attributes[4]["value"]][element.metadata.attributes[5]["value"]]["tags"]).includes(selectedTag)
  );
  const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
  

  // Change page
  const handlePaginationChange = (e, { activePage }) => setCurrentPage(activePage);

  // Options for items per page
  const itemsPerPageOptions = [
    { key: 1, text: '6 tokens per page', value: 6 },
    { key: 2, text: '12 tokens per page', value: 12 },
    { key: 3, text: '18 tokens per page ', value: 18 },
  ];

  // Change items per page
  const handleItemsPerPageChange = (e, { value }) => {
    setItemsPerPage(value);
    setCurrentPage(1); // Reset to first page to avoid empty page view
  };

  return (
    <>
      <Grid  stackable verticalAlign='middle' >
        

        <Grid.Row columns={2}>
          <Grid.Column textAlign={window.innerWidth>768?'left':'center'}>
            <Dropdown
              selection
              options={itemsPerPageOptions}
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              //style={{ marginBottom: '20px' }}
            />
            
          </Grid.Column>
          <Grid.Column textAlign={window.innerWidth>768?'right':'center'}>
            <Pagination
              activePage={currentPage}
              onPageChange={handlePaginationChange}
              totalPages={Math.ceil(filteredData.length / itemsPerPage)}
              ellipsisItem={null}
            />
          </Grid.Column>
        </Grid.Row>
        {tagOptions.length>0?
          <Grid.Row>
            <Grid.Column textAlign='center'>
              <Dropdown
                placeholder="Filter by Theme"
                fluid
                clearable
                selection
                options={tagOptions}
                onChange={(_, { value }) => setSelectedTag(value)}
              />
            </Grid.Column>
          </Grid.Row>
        :<></>}
      </Grid>
      <Divider hidden/>
      <Card.Group centered stackable itemsPerRow={props.collection===2?2:3}> 
        {currentItems.map((item) => (
          <>
            
            
              <RenderCards
                id = {item.metadata.id}
                minted = {item.supply}
                nftImage = {item.metadata}
                name = {item.metadata.name}
                description = {item.metadata.description}
                contractImage = {props.contractImage}
                contractName = {props.contractName}
                contract = {props.contract}
                file = {item.metadata.attributes[4]["value"]}
                taktok = {item.metadata.attributes[5]["value"]}
                selectedFilters = {props.selectedFilters}
                filter = {props.filter}
                collection = {props.collection}
                key = {item.metadata.id}
              />
            
          </>
        ))}
      </Card.Group>
      <Divider hidden/>
      <Grid columns={2} stackable verticalAlign='middle' >
        <Grid.Row>
          <Grid.Column textAlign={window.innerWidth>768?'left':'center'}>
            <Dropdown
              selection
              options={itemsPerPageOptions}
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              style={{ marginBottom: '20px' }}
            />
          </Grid.Column>
          <Grid.Column textAlign={window.innerWidth>768?'right':'center'}>
            <Pagination
              activePage={currentPage}
              onPageChange={handlePaginationChange}
              totalPages={Math.ceil(filteredData.length / itemsPerPage)}
              ellipsisItem={null}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default PaginatedList;