import React from 'react';
import {
    smartWallet,
    embeddedWallet,
    metamaskWallet,
    coinbaseWallet,
    walletConnect,
    paperWallet
  } from "@thirdweb-dev/react";
import {factoryAddress} from './GetSettings';

const smartWalletConfig = smartWallet({
    factoryAddress: factoryAddress,
    gasless: false,
    personalWallets: [metamaskWallet({ recommended: true }), coinbaseWallet(), walletConnect({projectId: "96b9fbb0b891d3645522ef708d6f8c33"},),paperWallet({paperClientId: "37e17287-f08d-4e71-8dd1-d6e6ec90c8a4"}),embeddedWallet()],
  });

export default smartWalletConfig;

/*

supportedWallets={[
        smartWallet(
          metamaskWallet({ recommended: true }),
          smartWalletOptions,
        ),
        smartWallet(
          coinbaseWallet(),
          smartWalletOptions,
        ),
        smartWallet(
          walletConnect({projectId: "96b9fbb0b891d3645522ef708d6f8c33"}),
          smartWalletOptions,
        ),
        smartWallet(
          embeddedWallet({ recommended: true }),
          smartWalletOptions,
        ),
        smartWallet(
          paperWallet({paperClientId: "37e17287-f08d-4e71-8dd1-d6e6ec90c8a4"}),
          smartWalletOptions,
        )
      ]}

*/