import React, { useEffect } from 'react';
import {  Divider,  Container, Header, Icon, Grid, Button, Segment } from 'semantic-ui-react';
import {  longFormName } from '../metadata/utils/GetSettings';
import FooterBlock from '../components/FooterBlock';
import HeaderBlock from '../components/HeaderBlock';
import RenderContacts from '../components/RenderContacts';
import ReactGA from 'react-ga4';
//const Tracking_Id = "G-EN342X165C";
//import IntelligentWaitingListForm from '../components/IntelligentWaitingListForm';

function Contact(){
  useEffect(()=>{
    ReactGA.send({ hitType: "pageview", page: "/contact", title: "Contact" });
    window.scrollTo(0, 0);
  },[]);
  return (
      <>
        <Container  textAlign='center'>
          <HeaderBlock header = "Get in Touch" subheader={"Please help us improve ".concat(longFormName," to serve you better.")} title={true}  key="contact"/>
          <RenderContacts/>
          
        </Container>
        <Divider hidden />
          <Divider hidden />
          <Divider hidden />
          <Divider hidden />
          {/*<Header textAlign='center' as='h3'>Please join our mailing list</Header>
                <IntelligentWaitingListForm context="mailing list"/>*/}
        <FooterBlock/>
      </>
    );
}
export default Contact;
