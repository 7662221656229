import React, { useEffect } from 'react';
import {  Divider,  Container } from 'semantic-ui-react';
import {  useAddress } from "@thirdweb-dev/react";
import { urlPath, shortFormName, shortFormNameSingular,tokenFamily } from '../metadata/utils/GetSettings';
import RenderSelectors from '../components/RenderSelectors';
import HeaderBlock from '../components/HeaderBlock';
import FooterBlock from '../components/FooterBlock';
import ReactGA from 'react-ga4';
//const Tracking_Id = "G-EN342X165C";



function MyTaktoks(props){
  const address = useAddress();
  useEffect(()=>{
    window.scrollTo(0, 0);
    (props.default===0)?(
      (address !== undefined)?(
        ReactGA.event({
          category: "Wallet Connection",
          action: "My ".concat(shortFormName," by ",address),
          nonInteraction: false, // optional, true/false
          transport: "xhr", // optional, beacon/xhr/image
        })
      ):(
          ReactGA.send({ hitType: "pageview", page: "/my".concat(shortFormName), title: "My ".concat(shortFormName) })
      )
    ):(
      (address !== undefined)?(
        ReactGA.event({
          category: "Wallet Connection",
          action: "My ".concat(shortFormName," > ",urlPath[props.default], " by ", address),
          nonInteraction: false, // optional, true/false
          transport: "xhr", // optional, beacon/xhr/image
        })
      ):(
          ReactGA.send({ hitType: "pageview", page: "/my ".concat(shortFormName,"/",urlPath[props.default]), title: "My ".concat(shortFormName," > ", urlPath[props.default]," > ", address) })
      )
    );

  },[]);
  return (
    <>
      <Container >
        <HeaderBlock header = {"My ".concat(shortFormName, props.default!==0?" > ":"", props.default!==0?tokenFamily[props.default]:"")} subheader={"Select an ".concat(shortFormNameSingular," family...")} title={true}  key="my"/>
        <Divider hidden />
        <Divider hidden />
        <RenderSelectors key ={(1).toString()} option ={1} default={props.default} />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
      </Container>
      <FooterBlock/>
    </>
  );
}
export default MyTaktoks;
