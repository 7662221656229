import React, { useContext, useEffect } from 'react';
import NavigationContext from '../context/navigation';
import {   Header, Loader, Image, Icon, Container, Grid, Divider } from 'semantic-ui-react';
import {   useContract, useNFTs, useNFT } from "@thirdweb-dev/react";
import FailMessage from '../components/RenderFailMessage';
import HeaderBlock from '../components/HeaderBlock';
import FooterBlock from '../components/FooterBlock';
import ShowNFTGallery from '../components/ShowNFTGallery';
import { contractAdd, urlPath, creatorMD } from '../metadata/utils/GetSettings';
import ReactGA from 'react-ga4';
//import IntelligentWaitingListForm from '../components/IntelligentWaitingListForm';

function Creator(){
  
  const { currentPath } = useContext(NavigationContext);
  const queriedCustomer = currentPath.split('/').at(-1);
  const { contract } = useContract(contractAdd["customers"]);
  const { data: customerNFT, isLoading:isReadingNfts, errorReadingNfts } = useNFT(contract, queriedCustomer);
  const cMD = JSON.parse(JSON.stringify(creatorMD));

  useEffect(()=>{
    ReactGA.send({ hitType: "pageview", page: "/creator", title: "Creator page # ".concat(queriedCustomer) });
    window.scrollTo(0, 0);
  },[]);

  if (!isNaN(Number(queriedCustomer)) && (currentPath.split('/').length===3)){  
    return (
      (isReadingNfts )? (
              
        <Header as='h4' icon textAlign='center' disabled key={'wait creator'}> 
            <Loader size='huge' active inline='centered' />
        </Header>
      
      ) : (
            (errorReadingNfts)?(
            
                <Header as='h4' icon textAlign='center' disabled key={'error creator'}> 
                    <Icon name='exclamation circle' color='red'/><Header.Content>There seems to be a problem... please try again</Header.Content>
                </Header>
            
            ):(
                (customerNFT.metadata.name!=="Failed to load NFT metadata")?
                  <>
                    <Container textAlign='center'>
                      <HeaderBlock header = {customerNFT.metadata.name} subheader={customerNFT.metadata.description!==null?customerNFT.metadata.description:<></>} title={true}  key="Creator"/>
                      <Grid columns={2} stackable textAlign='center' verticalAlign='middle'>
                        <Grid.Row>
                          
                          <Grid.Column>
                            <Image src={customerNFT.metadata.image} size='small' centered />
                          </Grid.Column>
                          <Grid.Column>
                            
                              {cMD[queriedCustomer]["description"]}
                              <Divider hidden />
                              Contact Details: <br/>
                              {cMD[queriedCustomer]["contact"][0]} <br/>
                              {cMD[queriedCustomer]["contact"][1]}, {cMD[queriedCustomer]["contact"][2]}
                            
                          </Grid.Column>
                          
                        </Grid.Row>
                      </Grid>
                      
                      <ShowNFTGallery key = {"Creator".concat(queriedCustomer)} id = {2} purpose={"creator"} view = {Number(queriedCustomer)}/>
                      
                    </Container>
                    <Divider hidden />
                      <Divider hidden />
                      <Divider hidden />
                      <Divider hidden />
                      {/*<Header textAlign='center' as='h3'>Please join our mailing list</Header>
                        <IntelligentWaitingListForm context="mailing list"/>*/}
                      <FooterBlock/>
                  </>
              :
                <>
                  <Container>
                    <HeaderBlock header = "" subheader="" title={true}  key="creator-fail"/>
                    <FailMessage/>
                  </Container>
                  <FooterBlock/>  
                </>
              )
          )
          
    )
  }
  else {
    return(
      <>
        <Container>
          <HeaderBlock header = "" subheader="" title={true}  key="profile-fail"/>
          <FailMessage/>
        </Container>
        <FooterBlock/>  
      </>
    )
  }
  
}

export default Creator;