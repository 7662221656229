import React, { useContext, useEffect  } from 'react';
import NavigationContext from '../context/navigation';
import {  Divider,  Container, Header, Loader, Icon } from 'semantic-ui-react';
import { ethers } from 'ethers';
import RenderSelectors from '../components/RenderSelectors';
import HeaderBlock from '../components/HeaderBlock';
import FooterBlock from '../components/FooterBlock';
import FailMessage from '../components/RenderFailMessage';
import {  useAddress, } from "@thirdweb-dev/react";
import {  urlPath, shortFormName, longFormName } from '../metadata/utils/GetSettings';
import ReactGA from 'react-ga4';
//import IntelligentWaitingListForm from '../components/IntelligentWaitingListForm';

const recordEvent = (eventText) =>{
  
  ReactGA.event({
    category: "Profile Visit ".concat(eventText),
    action: "Profile Visit ".concat(eventText),
    label: "Profile Visit ".concat(eventText), // optional
    value: 1, // optional, must be a number
    nonInteraction: false, // optional, true/false
    transport: "xhr", // optional, beacon/xhr/image
  });
}

function Profile(props){
    const address = useAddress();
    const { currentPath } = useContext(NavigationContext);
    const queriedAddress = currentPath.split('/').at(-1);

    useEffect(()=>{
      window.scrollTo(0, 0);
      if (((currentPath.split('/').length===3 && props.default===0) || (currentPath.split('/').length===4 && (props.default===1 || props.default===2 || props.default===3))) && ethers.utils.isAddress(queriedAddress)) {
        (props.default===0)?(
          (address !== undefined)?(
            ReactGA.event({
              category: "Wallet Connection",
              action: shortFormName.concat(" > Profile of ",queriedAddress," by ", address),
              nonInteraction: false, // optional, true/false
              transport: "xhr", // optional, beacon/xhr/image
            })
          ):
          ReactGA.send({ hitType: "pageview", page: {currentPath}, title: shortFormName.concat(" > Profile of ",queriedAddress) })
        ):(
            (address !== undefined)?(
              ReactGA.event({
                category: "Wallet Connection",
                action: shortFormName.concat(" > Profile > ",urlPath[props.default], " > ", " of ", queriedAddress, " by ", address),
                nonInteraction: false, // optional, true/false
                transport: "xhr", // optional, beacon/xhr/image
              })
            ):
            ReactGA.send({ hitType: "pageview", page: {currentPath}, title: shortFormName.concat(" > Profile > ",urlPath[props.default], " of ", queriedAddress) })
          
        );
      } else {
          ReactGA.event({
            category: "Illegal Address",
            action: "Illegal Address > profile > ".concat(currentPath),
            nonInteraction: true, // optional, true/false
            transport: "xhr", // optional, beacon/xhr/image
          })
      }
      
    },[]);
    if (((currentPath.split('/').length===3 && props.default===0) || (currentPath.split('/').length===4 && (props.default===1 || props.default===2 || props.default===3))) && ethers.utils.isAddress(queriedAddress)){
        
        return(
          <>
            <Container >
                <HeaderBlock header = {longFormName.concat(" Showcase")} subheader={queriedAddress} title={true}  key="profile"/>
                <Divider hidden />
                <Divider hidden />
                <RenderSelectors key ={(1).toString()} option ={2} default={props.default} targetAdd={queriedAddress}/>
                <Divider hidden />
                <Divider hidden />
                <Divider hidden />
                
            </Container>
            <Divider hidden />
              
            <Divider hidden />
            <Divider hidden />
            {/*<Header textAlign='center' as='h3'>Please join our mailing list</Header>
                <IntelligentWaitingListForm context="mailing list"/>*/}
            <FooterBlock/>
          </>  
        );
    }
    

  else return (
    <>
      <Container>
        <HeaderBlock header = "" subheader="" title={true}  key="profile-fail"/>
        <FailMessage/>
      </Container>
      <FooterBlock/>
    </>
    
  );
}

export default Profile;
