import React, { useEffect } from 'react';
import {  Header, Segment, Divider, Button, Icon, Container, Grid, Image,Label } from 'semantic-ui-react';
import Link from '../components/Link';
import { ButtonStyle, ButtonStyleOuter, PunchLine, urlPath, shortFormName, longFormName, ThemeColor, Administrator, Overview} from '../metadata/utils/GetSettings';
import HeaderBlock from '../components/HeaderBlock';
import FooterBlock from '../components/FooterBlock';
import {  useAddress } from "@thirdweb-dev/react";
import RecognizeImg from '../images/design 2 gold recognize coin.png';
import ThankImg from '../images/design 2 gold acknowledge coin.png';
import CelebrateImg from '../images/design 2 gold award coin.png';


import ReactGA from 'react-ga4';
//const Tracking_Id = "G-EN342X165C";

const recordEvent = (eventText) =>{
  ReactGA.event({
    category: "Button press ".concat(eventText),
    action: eventText,
    label: eventText, // optional
    value: 1, // optional, must be a number
    nonInteraction: false, // optional, true/false
    transport: "xhr", // optional, beacon/xhr/image
  });
}


function Home(){
  const address = useAddress();
  useEffect(()=>{
    window.scrollTo(0, 0);
    (address !== undefined)?(
      ReactGA.event({
        category: "Wallet Connection",
        action: shortFormName.concat(" by ",address),
        nonInteraction: false, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      })
    ):
    ReactGA.send({ hitType: "pageview", page: "/", title: shortFormName });
  },[]);
  return (
    <div>
      <HeaderBlock header = "" subheader=" " title={false} key="home"/>
      <Segment.Group size={window.innerWidth>768?'massive':'huge'}>
        <Segment  textAlign='center'>
          <Icon name='hand spock' size={window.innerWidth>768?'massive':'massive'} style={{color: ThemeColor}}/>
          <Header size='huge'  >
            Welcome to {window.innerWidth>768?<></>:<br/>} {longFormName}! 
          </Header>
          <Divider hidden />
          
          <Header size='Large' textAlign='center' color='orange'>
            <Image.Group size='small'>
              <Link to={'/discover'.concat('/',urlPath[1])}><Image src={RecognizeImg} /></Link>
              <Link to={'/discover'.concat('/',urlPath[2])}><Image src={CelebrateImg} /></Link>
              <Link to={'/discover'.concat('/',urlPath[3])}><Image src={ThankImg} /></Link>
            </Image.Group>
            <Header.Content><br/>{PunchLine}</Header.Content>
          </Header>

        </Segment>
        <Divider hidden />
      </Segment.Group>
      
      <Divider hidden />
      <Divider hidden />
      <Grid columns='equal' stackable textAlign='center'>
              
        <Grid.Column></Grid.Column>      
        <Grid.Column>
          <Link to={'/discover'}>
          {window.innerWidth>768?
              <Button  animated='fade' size='massive' style={ButtonStyleOuter}  onClick={()=>recordEvent('Discover Top Button Clicked')}>
                <Button.Content visible>Discover {shortFormName}</Button.Content>
                <Button.Content hidden><Icon name='binoculars' /><Icon name='gem' /></Button.Content>
              </Button>
              :
              

              <Button as='div'  labelPosition='left' size='huge' fluid onClick={()=>recordEvent('Discover Bottom Button Clicked')}  >
                <Label basic style={ButtonStyle} pointing='right'>
                  <Icon name='binoculars' /><Icon name='gem' />
                </Label>
                <Button fluid style={ButtonStyleOuter} size='huge'> Discover {shortFormName}</Button>
              </Button>
            }
          </Link>
        </Grid.Column>
        <Grid.Column>
          <Link to={'/mint'}>
            {window.innerWidth>768?
              <Button animated='fade' size='massive' style={ButtonStyleOuter}  onClick={()=>recordEvent('Try Bottom Button Clicked')}>
                <Button.Content visible>Try {shortFormName} </Button.Content>
                <Button.Content hidden><Icon name='trophy' /><Icon name='paper plane' /></Button.Content>
              </Button>
              :
              
              <Button as='div'  labelPosition='left' size='huge' fluid onClick={()=>recordEvent('Try Top Button Clicked')}  >
                <Label basic style={ButtonStyle} pointing='right'>
                  <Icon name='trophy' /><Icon name='paper plane' />
                </Label>
                <Button  fluid style={ButtonStyleOuter} size='huge'> Try {shortFormName}</Button>
              </Button>
            }
            
          </Link>
        </Grid.Column>
        <Grid.Column></Grid.Column>  
        
        
      </Grid>
      
      {/*
        address === Administrator?
        <DataUploader />:
        <></>
          */}  
        

        
          
          
          
          <Divider hidden />
          <Divider hidden />
                
      <FooterBlock/>
      
    </div>
  );
}
export default Home;
