import React, { useState } from 'react';
import { useContract, useAddress, useNFTs, useMetadata } from "@thirdweb-dev/react";
import { Card, Icon, Header, Segment,  Loader, Image, Grid, Divider, Container, List} from 'semantic-ui-react';
import RenderCards from './renderAllCards';
import {taxonomy1, taxonomy2, contractAdd, excludeList, quotes, shortFormNameSingular, mySteps, longFormName, useCasesProf, ThemeColor, tokenFamilyName, shortFormName} from '../metadata/utils/GetSettings';
import Link from './Link';
import ConceptImg4 from '../images/TakToken Concept 4.png';
import ConceptImg1 from '../images/TakToken Concept 1.png';
import ConceptImg3 from '../images/TakToken Concept 31.png';
//import IntelligentWaitingListForm from './IntelligentWaitingListForm';


function DiscoverComponent(props){
  
  const { contract } = useContract(contractAdd[props.id]);
  const { data: metadata, isLoading: loadingMetadata, errorMetadata } = useMetadata(contract);


  return (
    <div>

      <Divider hidden />
      <Divider hidden />
      {(props.id===0)?(
        <Header as='h4' textAlign='center' key={'select'.concat(props.id)}>
            <Header.Content>
                Please select an {shortFormNameSingular} family
            </Header.Content>
        </Header>
      ):(
            (loadingMetadata )? (
                <div>
                    <Header as='h4' icon textAlign='center' disabled key={'wait'.concat(props.id)}> 
                        <Loader size='huge' active inline='centered' />
                        <Header.Content>Please wait</Header.Content>
                    </Header>
                </div>
            ) : (
                    (errorMetadata)?(
                    <div>
                        <Header as='h4' icon textAlign='center' disabled key={'error'.concat(props.id)}> 
                            <Icon name='exclamation' color='red'/><Header.Content>Error in reading from the blockchain. Please try again later... Sorry!</Header.Content>
                        </Header>
                        
                    </div>
                    ):(
 
                        <>
                            <Header as='h2' textAlign='center' key={'title image'.concat(props.id)}>
                                <Image src = {metadata.image} size = 'massive' circular spaced />
                                {metadata.name}
                            </Header>

                            <Grid relaxed stackable centered columns={2} verticalAlign='middle' key={'grid'.concat(props.id)}>
                                
                                <Grid.Row>
                                    <Grid.Column>
                                        <Segment padded='very' vertical textAlign='center' key={'segment1'.concat(props.id)}>
                                            <Header as='h4' textAlign='center'color = 'grey' key={'title'.concat(props.id)}>
                                                <Header.Content key={'title content'.concat(props.id)}>
                                                    <Icon name='quote left' key={'quote left icon'.concat(props.id)}/>
                                                    <br /><br />
                                                    {quotes[props.id][0]}
                                                    <br />
                                                    <br/>
                                                    {quotes[props.id][1]}
                                                    <br /><br /><br /><br />
                                                    <Icon name='quote right' key={'quote right icon'.concat(props.id)}/>
                                                </Header.Content>
                                            </Header>
                                        
                                            <Header as='h3' textAlign='center' color = 'orange' key={'quote'.concat(props.id)}>
                                                <Header.Content key={'quote content'.concat(props.id)}>
                                                    {quotes[props.id][2]}
                                                </Header.Content>
                                            </Header>
                                        </Segment>
                                    </Grid.Column>
                                    <Grid.Column centered>
                                        <Image src={props.id===2?ConceptImg1:props.id===3?ConceptImg3:ConceptImg4} size='medium' centered fluid/>
                                    </Grid.Column>
                                </Grid.Row>
                                
                            </Grid>
                            <Divider hidden />
                            <Divider hidden />
                            
                            
                            <Segment placeholder>
                                <Grid  stackable columns={2} centered  divided >
                                    <Grid.Row>
                                        <Divider hidden />
                                        <Header as='h2' textAlign='center' key={'how does this work'.concat(props.id)}>
                                            How to use '{metadata.name}'?
                                            <Divider hidden />
                                            <Divider hidden />
                                        </Header>
                                        
                                    </Grid.Row>
                                    {mySteps[props.id].map((stepText, index1)=>
                                        <Grid.Row>
                                            <Grid.Column width={2}>
                                                <Header as='h3' textAlign='center' icon key={'column'.concat(props.id, stepText)}>
                                                    <Icon name={stepText[0] } style={{color: ThemeColor}}/>
                                                    {index1+1}{" . "}{stepText[1]}
                                                </Header>
                                            </Grid.Column>
                                            <Grid.Column textAlign='justified'>
                                                {props.id!==2 || stepText[2]!==""?stepText[2]:(
                                                    <>
                                                        
                                                            <b>Option 1: Self service</b><br/>
                                                            <Grid  centered celled='internally' >
                                                                <Grid.Row>
                                                                    <Grid.Column textAlign='center'><b>Steps</b></Grid.Column>
                                                                </Grid.Row>
                                                                    {mySteps[1].map((stepTex, index)=> 
                                                                        <Grid.Row>
                                                                            <Grid.Column width={3} textAlign='right'>
                                                                                    {index+1}
                                                                            </Grid.Column>
                                                                            <Grid.Column width={11} textAlign='left'>
                                                                                <Icon name={stepTex[0]} /> {" "} {stepTex[1]}
                                                                            </Grid.Column>
                                                                        </Grid.Row>
                                                                    )}

                                                               
                                                            </Grid>
                                                            <Divider hidden/>
                                                            <b>Option 2: Managed service</b><br/>
                                                            <Grid   centered celled='internally'>
                                                                <Grid.Row>
                                                                    <Grid.Column textAlign='center'><b>Steps</b></Grid.Column>
                                                                </Grid.Row> 
                                                                   
                                                                    {mySteps['managed'].map((stepTex, index)=> 
                                                                        <Grid.Row>
                                                                            <Grid.Column width={3} textAlign='right'>
                                                                                {index+1}
                                                                            </Grid.Column>
                                                                            <Grid.Column width={11} textAlign='left'>
                                                                                <Icon name={stepTex[0]} />
                                                                                <b>{stepTex[1]}</b> <br/>
                                                                                {stepTex[2]}
                                                                            </Grid.Column>
                                                                        </Grid.Row>
                                                                    )}

                                                                
                                                            </Grid>
                                                            
                                                        
                                                        
                                                    </>
                                                    ) 
                                                }
                                            </Grid.Column>
                                        </Grid.Row>

                                    )}
                                    
                                </Grid>
                            </Segment>
                            
                            <Divider hidden />
                            <Divider hidden />
                            <Divider hidden />

                            {props.id===2?(
                                <Grid relaxed stackable columns='equal' centered  >
                                    <Grid.Row>
                                        <Header as='h2' textAlign='center' key={'Some usecases for '.concat(props.id)}>
                                            Use cases for '{metadata.name}'
                                        </Header>
                                    </Grid.Row>
                                    <Grid.Row columns={window.innerWidth>768?3:6}>
                                        {useCasesProf.map((usecase, index)=>
                                            <>
                                              <Grid.Column>
                                                {index<(window.innerWidth>768?useCasesProf.length/2:useCasesProf.length)?
                                                    <>
                                                        <Header as='h4' textAlign='center' icon key={'column'.concat(props.id, usecase)}>
                                                            <Icon name={usecase[0]} style={{color: ThemeColor}}/>
                                                            {usecase[1]}
                                                        </Header>
                                                        <List bulleted>
                                                            {usecase[2].map((listItem)=>
                                                                <List.Item>{listItem}</List.Item>
                                                            )}
                                                        </List>
                                                    </>:<></>}
                                                </Grid.Column>
                                            </>
                                        
                                        )}
                                    </Grid.Row>
                                    {window.innerWidth>768?
                                        <Grid.Row columns={3}>
                                            {useCasesProf.map((usecase, index)=>
                                                <>
                                                    <Grid.Column>
                                                        {index>2?
                                                        <>
                                                        <Header as='h4' textAlign='center' icon key={'column'.concat(props.id, usecase)}>
                                                            <Icon name={usecase[0]} style={{color: ThemeColor}}/>
                                                            {usecase[1]}
                                                        </Header>
                                                        <List bulleted>
                                                            {usecase[2].map((listItem)=>
                                                                <List.Item>{listItem}</List.Item>
                                                            )}
                                                        </List>
                                                        </>:<></>}
                                                    </Grid.Column>
                                                </>
                                            
                                            )}
                                        </Grid.Row>
                                    :<></>
                                    }
                                    
                                    
                                </Grid>
                            )
                            :<></>}  

                            
                            
                        </>
                    )
                )   
        )}
        <Divider hidden />
    </div>);

}

export default DiscoverComponent;
