import React from 'react';
import ReactDOM from 'react-dom/client';
import 'semantic-ui-css/semantic.min.css';
import { ChainId, ThirdwebProvider, ThirdwebSDKProvider, smartWallet, embeddedWallet, metamaskWallet, coinbaseWallet, walletConnect, paperWallet } from "@thirdweb-dev/react";
import App from './App';
import { NavigationProvider } from "./context/navigation";
import walletDetails from "./metadata/utils/walletDetails";
import { ethers } from "ethers";
import {factoryAddress} from './metadata/utils/GetSettings';
import reportWebVitals from './reportWebVitals';
const activeChainId = ChainId.Polygon;
const  el = document.getElementById('root');
const root = ReactDOM.createRoot(el);

const smartWalletOptions = {
  factoryAddress: factoryAddress,
  gasless: true,
};
root.render(

    <ThirdwebProvider 
      activeChain="polygon"
      clientId="51738af30d2f177d64cc5fdd9682d696"
      supportedWallets={[
        metamaskWallet({ recommended: true }), 
        coinbaseWallet(), 
        walletConnect({projectId: "96b9fbb0b891d3645522ef708d6f8c33"},),
        //paperWallet({paperClientId: "37e17287-f08d-4e71-8dd1-d6e6ec90c8a4"}),
        //embeddedWallet()
      ]}
      
    >
      <NavigationProvider>
        <App />
      </NavigationProvider>
    </ThirdwebProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
