import React, { useState } from 'react';
import {  Button, Image,  Menu, Header } from 'semantic-ui-react';

import RecognizeImg from '../images/tt-recognize.png';
import ThankImg from '../images/tt-thank.png';
import CelebrateImg from '../images/tt-celebrate.png';
import {ButtonStyleSelected, ButtonStyle,  ButtonStyleInner, ButtonStyleSelectedInner, contractAdd, urlPath,shortFormName} from '../metadata/utils/GetSettings';
import ShowNFTGallery from './ShowNFTGallery';
import ShowOwnNFT from './ShowOwnNFT';
import DiscoverComponent from './DiscoverComponent';
import Link from './Link';
import RecognizeImgAlt from '../images/design 2 gold recognize coin.png';
import ThankImgAlt from '../images/design 2 gold acknowledge coin.png';
import CelebrateImgAlt from '../images/design 2 gold award coin.png';



const getSTyle = (cli,selected,level) =>{
  return((level===1)?((cli === selected)?ButtonStyleSelected:ButtonStyle):((cli === selected)?ButtonStyleSelectedInner:ButtonStyleInner)); 
}

function RenderSelectors(props) {
  const [clicked, trackClicked] = useState(props.default);
  const linkBase = {
    0 : "/mint",
    1 : "/my".concat(shortFormName),
    2 : "/profile",
    3 : "/discover"
  }

  const buttonHeaders = {
    1 : {
          0 : ["Appreciate","Recognize my peers","P2p ".concat(shortFormName)],
          1 : ["My Appreciations","P2P ".concat(shortFormName, " received from my peers"), "P2p ".concat(shortFormName)],
          2 : ["Appreciation",shortFormName.concat(" from peers"), "P2p ".concat(shortFormName)],
          3 : ["Appreciation","Discover ".concat(shortFormName, " for p2p recognition"), "P2p ".concat(shortFormName)]
        },
    2 : {
          0 : ["Award","Formally recognize team accomplishment","Formal ".concat(shortFormName)],
          1 : ["My Awards","Formal ".concat(shortFormName," awarded by my mentors"),"Formal ".concat(shortFormName)],
          2 : ["Awards","Formal ".concat(shortFormName," awarded by mentors"),"Formal ".concat(shortFormName)],
          3 : ["Awards","Discover ".concat(shortFormName," for formal recognition"),"Formal ".concat(shortFormName)]
        },
    3 : {
          0 : ["Acknowledge","I want to express gratitude...", "Acknowledgements"],
          1 : ["My Acknowledgements","Expressions of gratitude recieved", "Acknowledgements"],
          2 : ["Acknowledgements","Expressions of gratitude recieved", "Acknowledgements"],
          3 : ["Acknowledgement","Express gratitude", "Acknowledgements"]
        }    
    
  }
  return(
    <div>
      <Menu secondary pointing borderless widths ={3} stackable>
        <Menu.Item active = {(clicked===1)?(true):(false)}>
          <Header as='h2' textAlign='center'>
              {buttonHeaders[1][props.option][0]}
              <Header.Subheader>{buttonHeaders[1][props.option][1]}</Header.Subheader>
            <p></p>
            <Link to={props.option===2?(clicked!==1?linkBase[props.option].concat("/",urlPath[1],"/",props.targetAdd):(linkBase[props.option].concat("/",props.targetAdd))):(clicked!==1?linkBase[props.option].concat("/",urlPath[1]):linkBase[props.option])}>
            {window.innerWidth>768?
                  <Button animated='fade' basic onClick = {()=>trackClicked(1)} style = {getSTyle(clicked,1,1)} >
                    <Button.Content visible >
                      <Image src = {clicked===1?RecognizeImgAlt:RecognizeImg} size = 'tiny' circular centered />
                    </Button.Content>
                    <Button.Content hidden  >
                      <Header as ='h6' style = {getSTyle(clicked,1,0)}>
                        {buttonHeaders[1][props.option][2]}
                      </Header>
                    </Button.Content>
                  </Button>
                :
                  <Button basic onClick = {()=>trackClicked(1)} style = {getSTyle(clicked,1,1)}>
                    <Image src = {clicked===1?RecognizeImgAlt:RecognizeImg} size = 'tiny' circular centered />
                    <Header as ='h6' style = {getSTyle(clicked,1,0)}>
                      {buttonHeaders[1][props.option][2]}
                    </Header>
                  </Button>
            }
            </Link>
          </Header>
        </Menu.Item>
        <Menu.Item active = {(clicked===2)?(true):(false)}>
          <Header as='h2' textAlign='center'>
            {buttonHeaders[2][props.option][0]}
            <Header.Subheader>{buttonHeaders[2][props.option][1]}</Header.Subheader>
            <p></p>
            <Link to={props.option===2?(clicked!==2?linkBase[props.option].concat("/",urlPath[2],"/",props.targetAdd):(linkBase[props.option].concat("/",props.targetAdd))):(clicked!==2?linkBase[props.option].concat("/",urlPath[2]):linkBase[props.option])}>
              {window.innerWidth>768?
                <Button animated='fade' basic onClick = {()=>trackClicked(2)} style = {getSTyle(clicked,2,1)}>
                  <Button.Content visible>
                    <Image src = {clicked===2?CelebrateImgAlt:CelebrateImg} size = 'tiny' circular centered />
                  </Button.Content>
                  <Button.Content hidden >
                    <Header as ='h6' style = {getSTyle(clicked,2,0)}>
                      {buttonHeaders[2][props.option][2]}
                    </Header>
                  </Button.Content>
                </Button>
              :
                <Button basic onClick = {()=>trackClicked(2)} style = {getSTyle(clicked,2,1)}>
                  <Image src = {clicked===2?CelebrateImgAlt:CelebrateImg} size = 'tiny' circular centered />
                  <Header as ='h6' style = {getSTyle(clicked,1,0)}>
                    {buttonHeaders[2][props.option][2]}
                  </Header>
                </Button>
              }
            </Link>
          </Header>
        </Menu.Item>
        <Menu.Item active = {(clicked===3)?(true):(false)}>
          <Header as='h2' textAlign='center'>
            {buttonHeaders[3][props.option][0]}
            <Header.Subheader>{buttonHeaders[3][props.option][1]}</Header.Subheader>
            <p></p>
            <Link to={props.option===2?(clicked!==3?linkBase[props.option].concat("/",urlPath[3],"/",props.targetAdd):(linkBase[props.option].concat("/",props.targetAdd))):(clicked!==3?linkBase[props.option].concat("/",urlPath[3]):linkBase[props.option])}>
              {window.innerWidth>768?
                <Button animated='fade' basic onClick = {()=>trackClicked(3)} style = {getSTyle(clicked,3,1)}>
                  <Button.Content visible>
                    <Image src = {clicked===3?ThankImgAlt:ThankImg} size = 'tiny' circular centered />
                  </Button.Content>
                  <Button.Content hidden >
                    <Header as ='h6'style = {getSTyle(clicked,3,0)}>
                      {buttonHeaders[3][props.option][2]}
                    </Header>
                  </Button.Content>
                </Button>
              :
                <Button basic onClick = {()=>trackClicked(3)} style = {getSTyle(clicked,3,1)}>
                  <Image src = {clicked===3?ThankImgAlt:ThankImg} size = 'tiny' circular centered />
                  <Header as ='h6' style = {getSTyle(clicked,1,0)}>
                    {buttonHeaders[3][props.option][2]}
                  </Header>
                </Button>
              }
            </Link>
          </Header>
        </Menu.Item>
      </Menu>

      {(props.option===0)?(
        <ShowNFTGallery key = {clicked.toString()} id = {clicked}/>
      ):(
        (props.option!==3)?
          <ShowOwnNFT key = {clicked.toString()} id = {clicked} profile = {props.option} targetAdd={props.targetAdd} print={0}/>
        : <DiscoverComponent key = {clicked.toString()} id = {clicked}/>
      )}
    </div>

  );


}

export default RenderSelectors;
