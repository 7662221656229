import React from 'react';
import { Segment, Icon, Label, Grid, Divider  } from 'semantic-ui-react';

import Link from './Link';

const ShareLink = ({ link, path, collection, logo }) => {
  const handleCopy = () => {
    navigator.clipboard.writeText(link.concat(path));
    // You can add additional logic or feedback to indicate that the link has been copied
  };
  const handleCopy1 = () => {
    navigator.clipboard.writeText("/profile/".concat(path.split('/').at(-1)));
    // You can add additional logic or feedback to indicate that the link has been copied
  };

  const handleShareLinkedIn = () => {
    // Perform actions to share the link on LinkedIn
    // You can use a third-party library or the LinkedIn API to implement this functionality
    // This code block is a placeholder and would need to be replaced with the actual implementation
    //console.log('Share on LinkedIn clicked');
  };

  return (
    <div>
        <Grid columns={2} stackable textAlign='center'> 
            <Grid.Row verticalAlign='middle'>     
                <Grid.Column>
                    <Label basic>
                        <Link to={path}> 
                            <Icon name='id card outline' /> This collection in your showcase 
                        </Link>
                    </Label>    
                </Grid.Column>

                <Grid.Column>
                    <Label as='a' basic onClick={handleShareLinkedIn}>
                        <Icon name='linkedin' /> Share on LinkedIn
                    </Label>
                </Grid.Column>
            </Grid.Row>
        </Grid>  
    </div>
  );
};

export default ShareLink;
