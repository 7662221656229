import { useContext  } from 'react';
import NavigationContext from '../context/navigation';


function RouteUncertain({path,option,children}){
  const { currentPath } = useContext(NavigationContext);
  let result = true;
  path.split('/').map((item,index)=>(item===currentPath.split('/')[index]?result=result:result=false))
  if (result && (path.split('/').length+(option===1?1:2)===currentPath.split('/').length)){
    return children;
  }

  return null;
}

export default RouteUncertain;
