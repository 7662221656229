import React, { useState } from 'react';
import { Card, Image, Button,Loader, Input, Icon, Divider, Header, Label, List, LabelGroup } from 'semantic-ui-react';
import {   useContract, useAddress } from "@thirdweb-dev/react";
import RenderImageOnce from './RenderImageOnce';
import {taxonomy1, taxonomy2, ButtonStyle, TagStyle, ThemeColor, contractAdd, urlPath} from '../metadata/utils/GetSettings';
import Link from './Link';
import { ethers } from 'ethers';
import ReactGA from 'react-ga4';

export default function RenderCards(props) {
  const [count, setCount] = useState(0);
  
  const address = useAddress();
  const [toAddress, setToAddress] = useState("");
  const [submission, setSubmission] = useState('Ready');
  const [errors, recordErrors] = useState('');
  const metadata = JSON.parse(JSON.stringify((props.file!=="2")?taxonomy1:taxonomy2));

  const { contract } = useContract(props.contract);

  const handleSubmission = async () =>{
    setSubmission('submitted');
    try {
        const response = await contract.erc1155.transfer(toAddress, props.id, 1);

        setSubmission('success');

      } catch (err) {
        recordErrors(err);
        setSubmission('failure')
        (err.reason==="user rejected transaction")?(
          setSubmission('failure')
        ):(
            (err.cancelled)?(
              setSubmission('cancelled')
            ):(
              setSubmission('success')
            )
        );
      }
    }

    const handleClick = () =>{
      ((submission === 'success')||(submission === 'failure')||(submission === 'cancelled'))?(
        setSubmission('Ready')
      ):(
        <></>
      );
      setCount(count+1);
    }

  const handleChange = (event) =>{
    setToAddress(event.target.value);
  }

  const updateTag = (tax,level,newValue,status) =>{
    props.filter(tax,level,newValue);
    ReactGA.event({
      category: "MyTakTok Tags on ".concat(props.name),
      action: "MyTakTok > ".concat(tax," > ",level," : ", newValue),
      label: newValue, // optional
      value: status?1:0, // optional, must be a number
      nonInteraction: true, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });

  }

  const recordEvent = (queriedAssetNumber,queriedAddress) =>{
    ReactGA.event({
      category: "Asset Visit - Detailed ".concat(queriedAssetNumber, " owned by ", queriedAddress),
      action: "Asset Visit - Detailed".concat(queriedAssetNumber, " owned by ", queriedAddress),
      label: "Asset Visit - Detailed".concat(queriedAssetNumber, " owned by ", queriedAddress), // optional
      value: 1, // optional, must be a number
      nonInteraction: false, // optional, true/false
      transport: "xhr", // optional, beacon/xhr/image
    });
  }



  return(


      <Card raised key={props.name.concat(props.id)}>

        <RenderImageOnce src={props.nftImage}/>
        {(props.profile===1)&&(props.contract!==contractAdd[2])?
            props.transferable.net>0 ?
            <>
              <Label attached='top left' color = {!props.transferable.self?'green':'grey'}>
                {props.transferable.self?<>Self Minted</>:<>Recognition</>}
              </Label>
            </>  
            :<></>
          :
            (props.contract===contractAdd[2])?
              <Label attached='top left' color = {props.valid?'green':'red'}>
                {props.valid?<>Valid</>:<>Invalid</>}
              </Label>
            :<></>
            
          
          }
        <Card.Content >
          <Card.Header>
            <>
              {props.name}
            </>
          </Card.Header>
          <Card.Meta>
            Token id: {props.id}
            
            <Label.Group>
                {(metadata[props.taktok]["level"]!=="")?
                  (<Label key={"Level".concat(props.id)} as ="a"
                  onClick={()=>updateTag("competancy","",metadata[props.taktok]["level"],props.selectedFilters.competancy.has(metadata[props.taktok]["level"]))}>
                            <Icon name={props.selectedFilters.competancy.has(metadata[props.taktok]["level"])?'check circle':'circle outline'} />
                            <Icon name='level up' />
                            Level
                            <Label.Detail>
                              {metadata[props.taktok]["level"]}
                            </Label.Detail>
                  </Label>)
                : (<></>)}
            </Label.Group>
          </Card.Meta>
          <Card.Description>
            {props.valid?<>{props.description}</>:
              <>
                <Icon name = 'warning sign'  color='red'/>
                This is an invalid token
              </>
            }
          </Card.Description>
          <Divider hidden />
          <Divider  />
          <Card.Meta>
            {metadata[props.taktok]["tax"].map((taktokTag)=>(
              <>
                <Divider hidden/>
                <Header as='h5' >WEF Global Skills Taxonomy</Header>
                <Label.Group tag>
                {(taktokTag["ST"]["L1"]!=="")?
                  (<Label key={"STL1".concat(props.id)} style={TagStyle} as ="a"
                          onClick={()=>updateTag("ST","L1",taktokTag["ST"]["L1"],props.selectedFilters.ST.L1.has(taktokTag["ST"]["L1"]))}
                          >
                            
                            <Icon name={props.selectedFilters.ST.L1.has(taktokTag["ST"]["L1"])?'check circle':'circle outline'} />
                            L1
                            <Label.Detail>
                              {taktokTag["ST"]["L1"]}
                            </Label.Detail>
                  </Label>)
                : (<></>)}
                {(taktokTag["ST"]["L2"]!=="")?
                  (<Label key={"STL2".concat(props.id)} style={TagStyle} as ="a"
                          onClick={()=>updateTag("ST","L2",taktokTag["ST"]["L2"],props.selectedFilters.ST.L2.has(taktokTag["ST"]["L2"]))}
                          >
                            
                            <Icon name={props.selectedFilters.ST.L2.has(taktokTag["ST"]["L2"])?'check circle':'circle outline'} />
                            L2
                            <Label.Detail>
                              {taktokTag["ST"]["L2"]}
                            </Label.Detail>
                  </Label>)

                : (<></>)}
                {(taktokTag["ST"]["L3"]!=="")?
                  (<Label key={"STL3".concat(props.id)} style={TagStyle} as ="a"
                          onClick={()=>updateTag("ST","L3",taktokTag["ST"]["L3"],props.selectedFilters.ST.L3.has(taktokTag["ST"]["L3"]))}
                          >
                            
                            <Icon name={props.selectedFilters.ST.L3.has(taktokTag["ST"]["L3"])?'check circle':'circle outline'} />
                            L3
                            <Label.Detail>
                              {taktokTag["ST"]["L3"]}
                            </Label.Detail>
                  </Label>)

                : (<></>)}
                {(taktokTag["ST"]["L4"]!=="")?
                  (<Label key={"STL4".concat(props.id)} style={TagStyle} as ="a"
                          onClick={()=>updateTag("ST","L4",taktokTag["ST"]["L4"],props.selectedFilters.ST.L4.has(taktokTag["ST"]["L4"]))}
                          >
                            
                            <Icon name={props.selectedFilters.ST.L4.has(taktokTag["ST"]["L4"])?'check circle':'circle outline'} />
                            L4
                            <Label.Detail>
                              {taktokTag["ST"]["L4"]}
                            </Label.Detail>
                  </Label>)
                : (<></>)}
                </Label.Group>
                  
                
                <Divider hidden />
                <Header as='h5' >WEF Education 4.0 Taxonomy</Header>
                
                <Label.Group tag>
                {(taktokTag["ET"]["L1"]!=="")?
                  (
                    <Label key={"ETL1".concat(props.id)} style={TagStyle} as ="a"
                            onClick={()=>updateTag("ET","L1",taktokTag["ET"]["L1"],props.selectedFilters.ET.L1.has(taktokTag["ET"]["L1"]))}
                            >
                              
                              <Icon name={props.selectedFilters.ET.L1.has(taktokTag["ET"]["L1"])?'check circle':'circle outline'} />
                              L1
                              <Label.Detail>
                                {taktokTag["ET"]["L1"]}
                              </Label.Detail>
                    </Label>
                  ): (<></>)}
                {(taktokTag["ET"]["L2"]!=="")?
                (
                  <Label key={"ETL2".concat(props.id)} style={TagStyle} as ="a"
                          onClick={()=>updateTag("ET","L2",taktokTag["ET"]["L2"],props.selectedFilters.ET.L2.has(taktokTag["ET"]["L2"]))}
                          >
                            
                            <Icon name={props.selectedFilters.ET.L2.has(taktokTag["ET"]["L2"])?'check circle':'circle outline'} />
                            L2
                            <Label.Detail>
                              {taktokTag["ET"]["L2"]}
                            </Label.Detail>
                  </Label>
                ): (<></>)}
                {(taktokTag["ET"]["L3"]!=="")?
                (
                  <Label key={"ETL3".concat(props.id)} style={TagStyle} as ="a"
                          onClick={()=>updateTag("ET","L3",taktokTag["ET"]["L3"],props.selectedFilters.ET.L3.has(taktokTag["ET"]["L3"]))}
                          >
                            
                            <Icon name={props.selectedFilters.ET.L3.has(taktokTag["ET"]["L3"])?'check circle':'circle outline'} />
                            L3
                            <Label.Detail>
                              {taktokTag["ET"]["L3"]}
                            </Label.Detail>
                  </Label>
                ): (<></>)}
                </Label.Group>
                  
              </>
              ))
            }
          </Card.Meta>
        </Card.Content>
        {
        (props.transferable.self && props.profile===1 && props.contract!==contractAdd[2] )?(
          <Card.Content extra textAlign = 'center'>
            < >
              {((count%2===1))?(
                < >

                  <Input
                    action={
                      <Button
                        icon= 'send'
                        color = 'green'
                        disabled = {((toAddress==='')||(submission==='submitted')||(toAddress===address)||(!ethers.utils.isAddress(toAddress)))?(true):(false)}
                        onClick={handleSubmission}
                        />}
                    label={{ icon: 'address book outline' }}
                    labelPosition='left corner'
                    placeholder='To address...'
                    disabled = {(submission==='submitted')?(true):(false)}
                    onChange = {handleChange}

                    fluid
                  />

                  </ >
                ):(< ></ >)}
                {((count%2===1)||(submission==='success')||(submission === 'failure')||(submission === 'cancelled'))?(
                  < >
                  {(submission==='success')?(
                    < >
                      <Icon name = 'thumbs up' size='small' color='green' key={'Successfully minted'.concat(props.id)}/> Successfully Transfered
                    </ >):(
                              ((submission==='failure')||(submission==='cancelled'))?(
                                < >
                                  <Icon name = 'thumbs down' size='small' color='red' key={'Try again'.concat(props.id)}/> Transfer {submission}... please try again
                                </ >):(
                                          (submission==='submitted')?(
                                            < >
                                              Transfer to {toAddress} in progress <Loader size='small' active inline />
                                            </ >):(
                                                      (toAddress===address)?(
                                                        < >
                                                          <Icon name = 'minus circle' size='small' color='red' key={'Own minting not possible'.concat(props.id)}/>Transfer to your own wallet not possible
                                                        </ >):(
                                                                  (toAddress==='')?(
                                                                    'Please specify the target address'):(
                                                                      (!ethers.utils.isAddress(toAddress))?
                                                                        (
                                                                          < >
                                                                            <Icon name = 'warning sign' size='small' color='red' key={'double check'.concat(props.id)}/>Invalid address - please check and enter a valid address
                                                                          </ >
                                                                        ):(
                                                                            <>
                                                                              <Icon name = 'warning sign' size='small' color='red' key={'double check'.concat(props.id)}/>Action cannot be undone - please double check the target address before sending
                                                                            </>
                                                                        )
                                                                      )
                                                                    )
                                                    )
                                        )
                              )
                      }

                </ >
              ):(< ></ >)}
              {(submission==='submitted')?(< ></ >):(
                < >
                  <br/>
                  {
                    <Button
                      color = {(count%2===1)?('red'):('green')}
                      disabled = {(props.transferable.net>0)?(false):(true)}
                      onClick = {handleClick}
                      >
                        {
                            (count%2===0)?(
                              < >
                                {(props.transferable.net>0)?('Transfer Token'):('You have no Transferable Tokens')}
                              </ >
                              ):(
                                  ((submission==='success')||(submission === 'failure')||(submission === 'cancelled'))?(setCount(count+1)):(< >Cancel</ >)
                                )
                        }
                      </Button>
                  }

                  </ >)
                }
                < >
                  <br/>
                  # tokens owned = {props.transferable.net<0?0:props.transferable.net}
                  
                </ >
            </ >
          </Card.Content>
        ) :(
          <Card.Content extra textAlign = 'center'>
            <>
              {props.profile!==3 && (props.contract!==contractAdd[2])?(
                  <>
                    # tokens owned = {props.transferable.net<0?0:props.transferable.net}
                    <br/>From 
                    <Label.Group  size='mini'>
                      {props.transferable.in.map((item,index)=>(<Label  key={index}>{item.from}</Label>))}
                    </Label.Group>
                  </>
                ):(
                  <Link to= {"/asset/".concat(urlPath[2],"/",props.profile===1?address:props.target,"/",props.id)}>
                    <Button animated='fade' size='medium' fluid style={ButtonStyle} onClick={()=>recordEvent(props.id,props.profile===1?address:props.target)}>
                      <Button.Content visible style={{color: ThemeColor}}>View Details</Button.Content>
                      <Button.Content hidden style={{color: ThemeColor}}><Icon name='zoom-in' /></Button.Content>
                    </Button>
                  </Link>
                  
                )}
              
              
            </>
          </Card.Content>
        ) }
        
      </Card>

  );
}
/*
<Card.Content extra textAlign = 'center'>
          < >
            {((count%2===1))?(
              < >

                <Input
                  action={
                    <Button
                      icon= 'send'
                      color = 'green'
                      disabled = {((toAddress==='')||(submission==='submitted')||(toAddress===address))?(true):(false)}
                      onClick={handleSubmission}
                      />}
                  label={{ icon: 'address book outline' }}
                  labelPosition='left corner'
                  placeholder='To address...'
                  disabled = {(submission==='submitted')?(true):(false)}
                  onChange = {handleChange}

                  fluid
                />

                </ >
              ):(< ></ >)}
              {((count%2===1)||(submission==='success')||(submission === 'failure')||(submission === 'cancelled'))?(
                < >
                {(submission==='success')?(
                  < >
                    <Icon name = 'thumbs up' size='small' color='green'/> Successfully Transfered
                  </ >):(
                            ((submission==='failure')||(submission==='cancelled'))?(
                              < >
                                <Icon name = 'thumbs down' size='small' color='red'/> Transfer {submission}... please try again
                              </ >):(
                                        (submission==='submitted')?(
                                          < >
                                            Transfer to {toAddress} in progress <Loader size='small' active inline />
                                          </ >):(
                                                    (toAddress===address)?(
                                                      < >
                                                        <Icon name = 'minus circle' size='small' color='red'/>Transfer to your own wallet not possible
                                                      </ >):(
                                                                (toAddress==='')?(
                                                                  'Please specify the target address'):(
                                                                    < >
                                                                      <Icon name = 'warning sign' size='small' color='red'/>Please double check the target address
                                                                    </ >)
                                                                  )
                                                  )
                                      )
                            )
                    }

              </ >
            ):(< ></ >)}
            {(submission==='submitted')?(< ></ >):(
              < >
                <br/>
                {
                  <Button
                    color = {(count%2===1)?('red'):('green')}
                    disabled = {(props.transferable>0)?(false):(true)}
                    onClick = {handleClick}
                    >
                      {
                          (count%2===0)?(
                            < >
                              {(props.transferable>0)?('Transfer Token'):('You have no Transferable Tokens')}
                            </ >
                            ):(
                                ((submission==='success')||(submission === 'failure')||(submission === 'cancelled'))?(setCount(count+1)):(< >Cancel</ >)
                              )
                      }
                    </Button>
                }

                </ >)
              }
              < >
                # tokens owned = {props.balance}
                <br/>

                # tokens transferable ={props.transferable}

              </ >
          </ >
        </Card.Content>
*/