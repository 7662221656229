import React, { useEffect, useState } from 'react';
import {contractAdd, ERC1155_ABI} from '../metadata/utils/GetSettings';
import Web3 from 'web3';
import { ethers } from 'ethers';

function FetchHolders(props) { 
    const [events, setEvents] = useState([
        {

        }
    ]);

    useEffect(() => {
        if (props.id!==0) {
            const fetchEvents = async () => {
            if (window.ethereum!==undefined) {
                const provider = new ethers.providers.Web3Provider(window.ethereum);
                const contract = new ethers.Contract(contractAdd[props.id], new ethers.utils.Interface(ERC1155_ABI[props.id]), provider);
                
                const filter = contract.filters.TransferSingle(); // or any other event
                const logs = await provider.getLogs({
                fromBlock: 50968905,
                toBlock: 'latest',
                address: contractAdd[props.id],
                topics: filter.topics,
                });
                //console.log(logs);
                /*
                const timeStamp =  async (blockNumber) => {
                let ts = await provider.getBlock(blockNumber);
                //console.log(ts.timestamp);
                return ts.timestamp;
                }
                */
                
                const parsedLogs = logs.map( (log) =>  {
                const parsedLog = contract.interface.parseLog(log);
                console.log(parsedLog);
                //const block = await provider.getBlock(log.blockNumber);
                return {
                    amount: parseInt(parsedLog.args['value']['_hex'],16),
                    block: log.blockNumber,
                    operator: parsedLog.args[0],
                    from: parsedLog.args[1],
                    to: parsedLog.args[2],
                    tokenNo: parseInt(parsedLog.args['id']['_hex'],16),
                    hash:log.transactionHash,
                    //timestamp: new Date(block * 1000) ,
                    ...parsedLog.args
                    };
                });
                setEvents(parsedLogs);
                
                
            } else {
                const provider = new Web3.providers.HttpProvider("https://polygon-mainnet.infura.io/v3/1b3a6035167f4ac9a382fa56b7c15574");
                const web3 = new Web3(provider);
                const contract = new web3.eth.Contract(ERC1155_ABI[props.id], contractAdd[props.id]);

                const pastEvents = await contract.getPastEvents('TransferSingle', {
                fromBlock: 50968905,
                toBlock: 'latest'
                });
                
                const timeStamp = async (blockNumber) => {
                let ts = await web3.eth.getBlock(blockNumber);
                //console.log(new Date(Number(ts.timestamp)* 1000));
                return Promise.resolve(new Date(ts.timestamp* 1000));
                }
                //console.log(Number(pastEvents[0].returnValues.value));

                const parsedEvents = pastEvents.map( (event) =>  {
                //const parsedLog = contract.interface.parseLog(log);
                //const block = await provider.getBlock(log.blockNumber);
                //console.log(event);
                return {
                    amount: Number(event.returnValues.value),
                    block: Number(event.blockNumber),
                    operator: event.returnValues.operator,
                    from: event.returnValues.from,
                    to: event.returnValues.to,
                    tokenNo: Number(event.returnValues.id),
                    hash:event.transactionHash,
                    //timestamp: timeStamp(Number(event.blockNumber)) ,
                    //...parsedLog.args,
                    };
                });
                setEvents(parsedEvents);
                //console.log(parsedEvents);
            }
        
        };

    fetchEvents();
    }
        
    }, [contractAdd[props.id]]);

    const filterByToken =  (tId) => { 
        //const provider = new ethers.providers.Web3Provider(window.ethereum);
        let filtered =  new Set();
        let zeroAddress = "0x0000000000000000000000000000000000000000";
        
        events.map((event)=>{
            if (event['tokenNo']===tId){
                if (event['from']=== zeroAddress) {
                    filtered.add(event['to'])
                }
                if (event['to']=== zeroAddress) {
                    filtered.delete(event['from'])
                } 
                    
                
            }  
            
            
        })
        
        return Array.from(filtered).join(', ');  
    }

    return filterByToken(props.tId)
    
    
}
export default FetchHolders;


