import React, { useState, useEffect } from 'react';
import { Container, Dropdown, Image, Menu, Header, Divider, Icon, Segment, Button, Label } from 'semantic-ui-react';
import LogoImg from '../images/tt.png';
import { ConnectWallet, useAddress, useLogin, Web3Button, lightTheme } from "@thirdweb-dev/react";
import Link from './Link';
import { ButtonStyle, ThemeColor, PunchLine, Legals, longFormName, siteTitle, shortFormName, ButtonStyleOuter} from '../metadata/utils/GetSettings';
import '../styling/TtStyles.css';
import Welcome from './WelcomeScreen';
import ReactGA from 'react-ga4';

function SemanticHeader(props) {
  const [isMobile, setIsMobile] = useState(false);
  const { login, isLoading } = useLogin();
  const address = useAddress();
  //const { login } = useLogin();
  //const [isConnected,setIsConnected] = useState(address);
  
  

  useEffect(() => {
    const checkWidth = () => {
      const mobileWidth = 768; // 768px is a common mobile screen width
      const windowWidth = window.innerWidth;
      setIsMobile(windowWidth < mobileWidth);
    };
    checkWidth();
    window.addEventListener('resize', checkWidth);
    
    /*
    (address!==undefined)?(isConnected!==address?setIsConnected(address):<></>):(isConnected!==undefined?setIsConnected(undefined):<></>);
    console.log(isConnected);
    isConnected !== undefined?(
      ReactGA.event({
        category: "Wallet Connections",
        action: "Wallet # ".concat(isConnected, " connected"),
        label: "Wallet # ".concat(isConnected, " connected"), // optional
        value: 1, // optional, must be a number
        nonInteraction: false, // optional, true/false
        transport: "xhr", // optional, beacon/xhr/image
      })
    ):<></>
    */
    return () => {
      window.removeEventListener('resize', checkWidth);
      
    };
  }, []);

  const logoSrc = LogoImg;
  
  const links1 = [
    { label: 'Home', path: '/', icon :['home'] },
    { label: window.innerWidth>768?'Discover '.concat(shortFormName):'Discover', path: '/discover', icon:['binoculars'] },
    { label: window.innerWidth>768?'Recognize with '.concat(shortFormName):'Recognize', path: '/mint', icon:window.innerWidth>768?['search','trophy']:['trophy'] },
    { label: 'My '.concat(shortFormName), path: '/my'.concat(shortFormName), icon:window.innerWidth>768?['gem','certificate','handshake']:['gem'] },
    { label: 'My Showcase', path: '/profile/'.concat(address), icon:window.innerWidth>768?['gem','certificate','handshake','bullhorn']:['bullhorn'] },
    
  ];
  
  const links2 = [
    { label: 'Home', path: '/', icon :['home'] },
    { label: window.innerWidth>768?'Discover '.concat(shortFormName):'Discover', path: '/discover', icon:['binoculars'] },
    { label: window.innerWidth>768?'Recognize with '.concat(shortFormName):'Recognize', path: '/mint', icon:window.innerWidth>768?['search','trophy']:['trophy'] },
    //{ label: 'Create Custom AltCreds', path: '/mint-custom', icon:'trophy' },
    
  ];

  const compareFn = (a, b) => (a.path > b.path ? 1 : -1);

 const links0 = address===undefined?links2.sort(compareFn):links1.sort(compareFn);


 

  const renderLinks = () => {
    let links=[];
    links0.map((link) => (
      ("/".concat(window.location.pathname.split("/")[1])!==link.path)?(links.push(link)):(link)
    ));
    return links.sort(compareFn).map((link) => (
    
      <Menu.Item  key={link.label}  >
          <Link to={link.path}>
            {window.innerWidth>768?
              <Button animated='fade' size='medium' fluid style={ButtonStyle}>
                <Button.Content visible style={{color: ThemeColor}}>{link.label}</Button.Content>
                <Button.Content hidden style={{color: ThemeColor}}>
                  {link.icon.map((icn)=>(<Icon name={icn} />))}
                </Button.Content>
              </Button>
              :
              <>
                {/*<Button as='div'  labelPosition='left' size='medium' fluid   >
                  <Label basic style={ButtonStyleOuter} pointing='right'>
                    {link.icon.map((icn)=>(<Icon name={icn} />))}
                  </Label>
                  <Button basic style={ButtonStyle} fluid> {link.label}</Button>
                </Button>*/}
                {link.label}
              </>
            }
            
            
          </Link>
        </Menu.Item>
        
    ));
  };

  async function handleLogin() {
    // All of these fields are optional
    login({
      uri: "https://alternativecredentials.com.com",
      statement: "Please agree to our terms of service!",
      resources: ["https://legals.s3.filebase.com/20220721 Terms-of-service-termify.pdf","https://legals.s3.filebase.com/20220721 NFT terms and conditions.pdf"],
    });
  }

  const renderMobileMenu = () => {
    return (
      <>
        <Menu fixed="top" borderless size = 'large' >

            <Menu.Item header key='logo' >
              <Link to={'/'}><Image size="tiny" src={logoSrc} /></Link>
            </Menu.Item>
            {(props.title)?(
                <Menu.Item  header key='title'>
                  <Header size='large' textAlign='center'>{longFormName}</Header>
                </Menu.Item>
              ):(<div></div>)
            }

            
              <Menu.Menu position="right" >
                <Dropdown item icon="bars" simple>
                  <Dropdown.Menu >
                    {renderLinks().map((ddi)=>(<Dropdown.Item>{ddi}</Dropdown.Item>))}
                    
                    <Dropdown.Item > 
                      <ConnectWallet
                        theme={lightTheme({
                          fontFamily:"Lato,Helvetica Neue,Arial,Helvetica,sans-serif",
                          colors:{
                            primaryButtonBg: ThemeColor,
                            accentText: ThemeColor,
                            primaryText: ThemeColor,
                            secondaryIconColor: ThemeColor,
                            secondaryButtonText: ThemeColor,
                            //secondaryText: "#f2711c",
                            danger: "#e63946",
                            accentButtonBg: ThemeColor,
                            primaryButtonText: "#ffffff",
                            accentButtonText: "#ffffff",
                            accentText: ThemeColor,
                            borderColor: ThemeColor,
                          }
                        })}
                        modalTitle={"Please Choose Your Wallet"}
                        btnTitle={"Please Connect Your Wallet"}
                        
                        modalSize={"compact"}
                        welcomeScreen={{
                          img: {
                            src: "https://ipfs.io/ipfs/QmWe51CGG54yY6ZuXXyDiNBD1eFe3QDhs54HHVBtVtijPu/TT%20%20Logos%20no%20circle.svg",
                            width: 150,
                            height: 150,
                          },
                          title: "Welcome to ".concat(longFormName),
                          subtitle: {PunchLine},
                          fontFamily:"Lato,Helvetica Neue,Arial,Helvetica,sans-serif",
                        }}
                        modalTitleIconUrl={
                          "https://ipfs.io/ipfs/QmWe51CGG54yY6ZuXXyDiNBD1eFe3QDhs54HHVBtVtijPu/TT%20%20Logos%20no%20circle.svg"
                        }
                        termsOfServiceUrl={Legals[2]}
                        privacyPolicyUrl={Legals[0]}
                        switchToActiveChain={true}
                      />
                      
                      
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Menu.Menu>
            
        </Menu>
        
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        
        {(props.header==="")?(
          <>
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
          </>
        ):(
          <>
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Segment padding = "very" vertical >
              <Header as='h1' textAlign='center'>
                {props.header}
                <Header.Subheader>
                  {props.subheader}
                </Header.Subheader>
              </Header>
            </Segment>
          </>
        )
        }
        


        
      </>
    
    );
  };

  const renderDesktopMenu = () => {
    return (
      <>
        <Menu fixed="top" borderless >

            <Menu.Item  header key='logo'>
              <Link to={'/'}><Image size="tiny" src={logoSrc} /></Link>
            </Menu.Item>
            {(props.title)?(
                <Menu.Item  header key='title'>
                  <Link to={'/'}><Header size='huge' textAlign='center'>{longFormName}</Header></Link>
                </Menu.Item>
              ):(<div></div>)
            }

          <Menu.Menu position="right">
            
            {renderLinks()}
            
            <Menu.Item>
              
            <ConnectWallet
              theme={lightTheme({
                fontFamily:"Lato,Helvetica Neue,Arial,Helvetica,sans-serif",
                colors:{
                  primaryButtonBg: ThemeColor,
                  accentText: ThemeColor,
                  primaryText: ThemeColor,
                  secondaryIconColor: ThemeColor,
                  secondaryButtonText: ThemeColor,
                  //secondaryText: "#f2711c",
                  danger: "#e63946",
                  accentButtonBg: ThemeColor,
                  primaryButtonText: "#ffffff",
                  accentButtonText: "#ffffff",
                  accentText: ThemeColor,
                  borderColor: ThemeColor,
                }
              })}
              modalTitle={"Choose Your Wallet"}
              btnTitle={"Connect Your Wallet"}
              modalSize={"wide"}
              welcomeScreen={()=>{return <Welcome />}}
              modalTitleIconUrl={
                "https://ipfs.io/ipfs/QmWe51CGG54yY6ZuXXyDiNBD1eFe3QDhs54HHVBtVtijPu/TT%20%20Logos%20no%20circle.svg"
              }
              termsOfServiceUrl={Legals[2]}
              privacyPolicyUrl={Legals[0]}
              switchToActiveChain={true}
            />
              
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Divider hidden />
        <Divider hidden />
        <Divider hidden />
        {(props.header==="")?(<>
          <Divider hidden />
            <Divider hidden />
            <Divider hidden />
        </>):(
          <>
            
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Divider hidden />
            <Segment padding = "very" vertical >
              <Header as='h1' textAlign='center'>
                {props.header}
                <Header.Subheader>
                  {props.subheader}
                </Header.Subheader>
              </Header>
            </Segment>
          </>
        )
        }
        
        
      </>
    );
  };

  return isMobile ? renderMobileMenu() : renderDesktopMenu();
}

export default SemanticHeader;
