import ABI1 from '../abi1.json';
import ABI2 from '../abi2.json';
import ABI3 from '../abi3.json';
import taxonomy01 from '../1.json';
import taxonomy02 from '../2.json';
import creatorMD_ from '../creator.json';
import certificateMD_ from '../certificate.json';
import Names from '../namalu.json';
import Link from '../../components/Link';
import certImg from '../../images/exampleCert.png';
import certImgQR from '../../images/exampleCertQR.png';
import certImgError from '../../images/exampleNameError.png';
import certImgNoError from '../../images/exampleNameNoError.png';
import {  Image, Icon } from 'semantic-ui-react';
export const urlText = "https://skillaccolades.com";
export const taxonomy1 = taxonomy01;
export const taxonomy2 = taxonomy02;
export const creatorMD = creatorMD_;
export const certificateMD = certificateMD_;
export const TTNames = Names;
export const urlPath = {
                            1:"appreciate",
                            2:"award",
                            3:"acknowledge",
                            //4:"celebrate",
                        };
export const tokenFamily = {
                            1:"Capability Tokens",
                            2:"Proficiency Tokens",
                            3:"Tak Tokens",
                            //4:"celebrate",
                        };

export const tokenFamilyName = {
                            1:"Appreciate",
                            2:"Award",
                            3:"Acknowledge",
                            //4:"celebrate",
                        };

export const ERC1155_ABI ={
                                0:ABI1,
                                1:ABI1,
                                2:ABI2,
                                3:ABI3
                            };
export const contractAdd = {
                                1:"0x5a5644268E9f2fb92B74cbd9c1fC3fB205a9D0D3",
                                2:"0x6bA25207f07d09087443a39f40173170A95f0F8C",
                                3:"0xa9dB7B8E702c4451A3dd867Dc8Ea5323c159A19E",
                                customers:"0x59fBbF9913C0EA007b88eb7a79d4CB0b2418c550",
                            };
export const polygonScanLink = "https://polygonscan.com/";

export const myURL = "https://skillaccolades.com";

export const longFormName = "Skill Accolades";

export const siteTitle = "Skills & Accolades";

export const shortFormName = "Accolades";

export const shortFormNameSingular = "Accolade";

export const ThemeColor = '#0044AA';


export const excludeList = {
                                1:[],
                                2:[],
                                3:[],
                                customers:[]
                            };
export const quotes = {
    1: ["“There is more hunger for love and appreciation in this world than for bread.”",
        "-Mother Teresa",
        "Let your colleagues know how their unique qualities and behaviors have commanded your respect. Nurture these qualities and recognize their contributions using these peer-to-peer tokens."
        ],
    2: ["“Without continual growth and progress, such words as improvement, achievement, and success have no meaning”",
        "-Benjamin Franklin, Founding Father of the US & Polymath",
        "Used by mentors, coaches, teachers, trainers, managers and other leaders, these Alternative Credentials help celebrate accomplishments of their teams, communities, disciples or mentees - recognizing accomplishments and appreciating them for their grit and discipline."
        ],
    3: ["“Motivation is overrated - environment often matters more”",
        "-James Clear, author of Atomic Habits",
        "Express your gratitude for the nurturing support from your team using these universal tokens"
        ]
  };

  export const discoverMessages = {
    0: ["".concat(longFormName, " are tokenized recognitions that represent your skills, accomplishments and contributions"),
        "Your ".concat(shortFormName, " are Soul Bound Tokens (non-transferable NFTs) exclusively owned and controlled by you"),
        "These digital assets can be showcased as dynamic skill stacks that uniquely represent the value you bring to a team",
        "Each ".concat(shortFormNameSingular, " is mapped to the World Economic Forum's Global Skills Taxonomy and Education 4.0 Taxonomy"),
        ],
    1: ["".concat(tokenFamilyName[1], " ",shortFormName, " are informal peer to peer recognitions that represent your reputation"),
        "Your ".concat(tokenFamilyName[1], " ",shortFormName, " represent your contributions as recognized by your peers and colleagues"),
        "You can use ".concat(tokenFamilyName[1], " ",shortFormName, " to encourage and appreciate the great qualities of your colleagues"),
        "".concat(tokenFamilyName[1], " ",shortFormName, " are powered by ", tokenFamily[1])
        ],
    2: ["".concat(tokenFamilyName[2], " ",shortFormName, " are formal recognitions"),
        "Your ".concat(tokenFamilyName[2], " ",shortFormName, " represent your officially presented awards and recognitions of achievement or accomplishment"),
        "You can use ".concat(tokenFamilyName[2], " ",shortFormName, " to issue formal credentials to celebrate the accomplishments of the team you officially lead"),
        "".concat(tokenFamilyName[2], " ",shortFormName, " are powered by ", tokenFamily[2])
        ],
    3: ["".concat(tokenFamilyName[3], " ",shortFormName, " are expressions of gratitude"),
        "Your ".concat(tokenFamilyName[3], " ",shortFormName, " represent acknowledgements for your contributions recognized by your colleagues"),
        "You can use ".concat(tokenFamilyName[3], " ",shortFormName, " to express your gratitude for the support you received"),
        "".concat(tokenFamilyName[3], " ",shortFormName, " are powered by ", tokenFamily[3])
        ]
  };

export const mySteps = {
    1: [
            ["sign-in","Log-in",
            <>
                <b>Connect your crypto wallet to {longFormName}</b> <br/><br/>
                <b>Prerequisite:</b> You need a crypto-currency wallet to use Skill Accolades.<br/><br/>
                Your wallet contains private information that identifies and assesses the crypto-assets you own.
                Connecting your wallet is the way you can log in to {longFormName}<br/><br/>
                Learn about wallets <a href="https://crypto.com/university/crypto-wallets" target="blanlk">here</a>. <br/><br/>
                We recommend that you use a non-custodial wallet like MetaMask.<br/>
                            
                In case you do not have such a wallet, you can use your email and get a custodial wallet.  
                <br/>
                Learn about <a href="https://crypto.com/university/custodial-vs-non-custodial-wallets" target="_blank">custodial and non-custodial wallets</a>
            </>],
            ["search","Select",<b>Browse the collection and select the accolade to send</b>],
            ["share","Send", <b>Specify the wallet address of the recipient, hit send and sign the transaction in your wallet</b>],
            ["gem","View",<b>Once wallet confirms the transaction, the recipient can view the accolade in <Link to={"/my".concat(shortFormName)}>'My Accolades'</Link> page or their wallet</b>]
        ],
    2:  [
            ["configure","Set up",<b>The tokens in this {shortFormName} family are made to order. Please <Link to='/contact'>contact us</Link> to set up your awards.</b>],
            ["certificate","Send",""],
            ["download","Download & Share", <><b>Recipients download their accolades as pdf and share it as needed</b><br/>Here is an example certificate: <br/> <Image src={certImg}  centered/> </>],
            ["qrcode","Verify",
            <>
                <b>Anyone can verify a pdf accolade using the QR code</b> <br/><br/>
                When certificates are shared, there is a risk of fraud and misuse. This can be easily detected by anyone using the QR code. <br/><Image src={certImgQR} size="medium"  centered/><br/>
                <Icon name='question circle' style={{color: ThemeColor}}/> check if the token is valid <br/>
                <Icon name='question circle' style={{color: ThemeColor}}/> check if the name on the certificate is valid <br/><br/><Image src={certImgNoError} size="medium"  centered/><br/><Image src={certImgError} size="medium"  centered/>
            </>]
        ],
    3:  [
            ["sign-in","Log-in",
            <>
                <b>Connect your crypto wallet to {longFormName}</b> <br/><br/>
                <b>Prerequisite:</b> You need a crypto-currency wallet to use Skill Accolades.<br/><br/>
                Your wallet contains private information that identifies and assesses the crypto-assets you own.
                Connecting your wallet is the way you can log in to {longFormName}<br/><br/>
                Learn about wallets <a href="https://crypto.com/university/crypto-wallets" target="blank">here</a>.  
            </>],
            ["search","Select",<b>Browse the collection and select the accolade to send</b>],
            ["share","Send", <b>Specify the wallet address of the recipient, hit send and sign the transaction in your wallet</b>],
            ["gem","View",<b>Once wallet confirms the transaction, the recipient can view the accolade in <Link to={"/my".concat(shortFormName)}>'My Accolades'</Link> page or their wallet</b>]
        ],
    'managed': [
            ["ordered list","Specify",<>Specify the recipients of award accolades</>],
            ["share","Send",<>We send the accolades to the recipients as specified</>],
            ["gem","View",<>Recipients can view the accolade in <Link to={"/my".concat(shortFormName)}>'My Accolades'</Link> page or their wallets</>]
        ]
};

    
export const useCasesProf = [
    ["university","Educational Proficiency",["Course Completion","Certifications","Degrees"]],
    ["factory","Professional Proficiency",["Performance and Service awards","Verifiable certificates for regulatory compliance","Special recognitions"]],
    ["star","Skill-Based Proficiency",["Technical Skills","Soft Skills","Language Skills"]],
    ["users","Community Proficiency",["Volunteer Work","Social Impact","Mentoring"]],
    ["idea","Creative Proficiency",["Artistic Achievements","Innovation","Design Skills"]],
    ["quidditch","Sports and Fitness Proficiency",["Athletic Achievements","Fitness Milestones","Coaching Skills" ]]
];


export const Administrator = '0x5b6897B06d13419385877990f4e28Da8C436A6dC';

export const PaperWallets = [
                                '0xf3DB642663231887E2Ff3501da6E3247D8634A6D',
                                '0x5e01a33C75931aD0A91A12Ee016Be8D61b24ADEB',
                                '0x9E733848061e4966c4a920d5b99a123459670aEe'
                            ];

export const ButtonStyleOuter = {
                                    backgroundColor: ThemeColor,
                                    color: 'white'
                                };

export const ButtonStyle = {
                                backgroundColor: 'white',
                                color: ThemeColor,
                                border: '1px solid '.concat(ThemeColor)
                            };

export const ButtonStyleInner = {
                                    color: ThemeColor
                                };

export const TagStyle = {
                            backgroundColor: ThemeColor,
                            color: 'white', 
                        };

export const HeaderStyle = {
                                    color: ThemeColor
                                };

export const factoryAddress = "0x40C937468E91Bf6722c29cd94C406Ba26884a2fC";


  
export const ButtonStyleSelected = {
                                        color: '#FF6400',
                                        border: '1px solid #FF6400'
                                    };
  
export const ButtonStyleSelectedInner = {
                                            color: '#FF6400'
                                        };

//export const PunchLine = 'Your currency in the skills-first universe';
export const PunchLine = 'Your Digital Currency in the Skills-First Metaverse';

export const Overview = "Skill Accolades is a simple yet powerful platform designed to give complete control (possession, custodianship, and ownership) of individual achievements and recognitions (both formal and peer-to-peer) to recipients. Harnessing the power of blockchain technology, this platform transforms achievements and recognitions into digital assets called accolades that are uniquely owned and controlled by the recipients.";

export const callToAction = "Nurture your network with ".concat(longFormName,);

export const Legals = {
                        PP: 'https://coincentives-my.sharepoint.com/:b:/g/personal/bc_coincentives_io/Eciqm4OTTdtOlnStB2ZrYS8B-JcXNhHi9VvO9J3rF4uBzA?e=Scxtuz',
                        TC: 'https://coincentives-my.sharepoint.com/:b:/g/personal/bc_coincentives_io/Eciqm4OTTdtOlnStB2ZrYS8BWlMZKowSch7olF9E9wWMnw?e=pcx7l3',
                        NFT:'https://coincentives-my.sharepoint.com/:b:/g/personal/bc_coincentives_io/EZcSogi_MzdIv7z0Y1cp2RwBervr7gZDsbdGp57gRIWGZA?e=aGDUeD'
                    };  